
import { React, useEffect, useState, Component, Fragment } from "react";
import aboutusbanner from '../img/aboutusbanner.png';
import { Bs1CircleFill, Bs2CircleFill, Bs3CircleFill, Bs4CircleFill, Bs5CircleFill, Bs6CircleFill, Bs7CircleFill } from "react-icons/bs";
import Admissionimg from '../../img/admission.svg'
import { BsGlobe } from "react-icons/bs";
import OwlCarousel from "react-owl-carousel";
import "../../../node_modules/owl.carousel/dist/assets/owl.carousel.css";
import "../../../node_modules/owl.carousel/dist/assets/owl.theme.default.css";
import { Spinner } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import lister1 from '../img/lister1.jpg';
import iconlister from '../img/iconlister.jpg';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import apiUrl from '../../setting/apiConfig';
import Table from 'react-bootstrap/Table';
const Mbbsinrussia = () => {
    const options = {
        loop: true,
        margin: 10,
        items: 1,
        autoplay: true,
        responsive: {
            0: {
                items: 1,
                nav: true,

            },
            600: {
                items: 3,
            },
            1000: {
                items: 4,
            },
        },
    };
    const [universities, setUniversities] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        fetch('https://webcrmapi.nayeedisha.in/api/university-details')
            .then((response) => response.json())
            .then((data) => {
                // Assuming 'results' contains the array of universities
                setUniversities(data);
                setIsLoading(false);

            })
            .catch((error) => {
                setIsLoading(false);
                console.error('Error fetching data:', error);
            });
    }, []);


    const [russiaUniversities, setRussiaUniversities] = useState([]);
    const [nepalUniversities, setNepalUniversities] = useState([]);


    // show university according country
    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);

                // Check if data is available in localStorage
                const cachedData = localStorage.getItem('university-details');

                if (cachedData) {
                    const universityData = JSON.parse(cachedData); // Use cached data

                    // Filter universities for Russia and Nepal
                    const russiaFiltered = universityData.filter(university => university.country === 'Russia');
                    const nepalFiltered = universityData.filter(university => university.country === 'Nepal');

                    // Update state with filtered data
                    setRussiaUniversities(russiaFiltered);
                    setNepalUniversities(nepalFiltered);

                    setIsLoading(false);
                } else {
                    const universityResponse = await fetch(apiUrl + 'university-details');
                    const universityData = await universityResponse.json();

                    // Store fetched data in localStorage
                    localStorage.setItem('university-details', JSON.stringify(universityData));
console.log(universityData);
                    // Filter universities for Russia and Nepal
                    const russiaFiltered = universityData.filter(university => university.country === 'Russia');
                    const nepalFiltered = universityData.filter(university => university.country === 'Nepal');

                    // Update state with filtered data
                    setRussiaUniversities(russiaFiltered);
                    setNepalUniversities(nepalFiltered);

                    setIsLoading(false);
                }
            } catch (error) {
                setIsLoading(false);
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);
    return (
        <>



            {/* section1 */}

            <div className='abt-section pt-5 pb-5'>


                <div className='container'>

                    <h3>MBBS In <span>Russia</span></h3>


                    <Breadcrumb>
                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                        <Breadcrumb.Item className="text-white" active>
                            MBBS In Russia
                        </Breadcrumb.Item>

                    </Breadcrumb>
                    <div className='bds'></div>
                    <div id='brdrs'></div>

                    {/* <p>It is a long established fact that a reader.</p> */}


                </div>


            </div>




            <div className='container abt-2 mt-5 mb-5'>


                <p className='mb-4'>
                Russia is the biggest country of the world geographically located in both European and Asian territories, that’s why it attracts travelers with an astounding number of different landscapes starting from the ice of Siberia and finishing with the forests and mountains.Moscow as the capital and being the political, economical and cultural heart of the country, Saint-Petersburg known for imperial tradition and history. Many students from India travel to Russia to pursue higher education particularly in the medical, engineering technology and sciences.. One of the major attractions of Russian Universities is the low cost affordable fees and globally acclaimed degrees. Festivals, art, exhibitions, and the student exchange program enhance people's contact between the two countries. 

                </p>
                
                
            </div>

            <section id="mbbs_abroad" className="py-4 bg-light">
              <div className="container">

<h2 className="h3 fw-bold my-3">
CLIMATE
                       
                    </h2>
              <p>Climate ranges widely in Russia. Here is an overview of Russia’s climate:</p>
              <ul>
                <li><b>Winter (December to February)</b> Winter in Russia is harsh especially in northern parts of Russia and Siberia. The temperature goes from -10°C to -30°C in central and northern parts of Russia and -50°C in Siberia. </li>
                <li><b>Spring (March to May)</b>
                March and May is usually characterized by a slow rising of temperature together with melting of snow. The temperature goes from 0°C to 15°C in most parts of the country.

                </li>
              
                
<li>
<b>Summer (June to August)</b>
                <p>Autumn is warmer and comfortable, with changing colorful leaves and more frequenting rainfall. The temperature goes from 10°C to 20°C.</p>
                </li>
                <li>
                <b>Autumn (September to November)</b>Autumn is warmer and comfortable, with changing colorful leaves and more frequenting rainfall. The temperature goes from 10°C to 20°C.

                </li>
                  
              </ul> 

              <h2 className="h3">Culture:</h2>
              <p>Russia is rich with its culture and history. Russia has one of the greatest literature 
Russian culture is rich and ancient with many performances, arts, literature, music, and buildings.Russia is unique with its religious architecture, for example with the onion-shaped Orthodox Church building. The country has also provided classics to the literatures with Leo Tolstoy, Fyodor Dostoevsky, Anton Chekhov, music with the likes of Tchaikovsky, Rachmaninoff etc. 
</p>
              </div>
              
            
            </section>
            <section id="eligibilitycriteria" className="py-4">
                <div className="container">






                    <h3 className="fw-bold my-3">
                    MBBS IN  

                        <span className="text-primary">&nbsp; RUSSIA</span>
                    </h3>
                    <Table striped bordered hover className="mt-3">
                        <thead>
                            <tr>
                                <th>ASPECTS</th>
                                <th>DETAILS</th>
                               

                            </tr>
                        </thead>
                        <tbody>
                            <tr>

                                <td>Duration</td>
                                <td>6 years including 1 year internship
 </td>
                            </tr>
                            <tr>

                                <td>Tuition fees (per year)</td>
                                <td>₹2,50,000 - ₹6,00,000 per year</td>
                            </tr>
                            <tr>
                                <td>Quality of education </td>
                                <td>High quality of education with strong research program</td>
                               
                              
                            </tr>
                            <tr>
                              <td>
                              Language 
                              </td>
                              <td>
                              English
                              </td>
                             
                            </tr>
                            <tr>
                            <td>Recognition and licensing </td>
                              <td>WHO, NMC and Graduates can take licensing exams like FMGE/NExT</td>
                            </tr>
<tr>
  <td>Admission process</td>
  <td>50% in PCB
</td>
</tr>

<tr>
  <td>Living expenses</td>
  <td>₹15,000 - ₹20,000 (approximate, depending on lifestyle and location)</td>


</tr>

                        </tbody>
                    </Table>
                </div>
            </section>
            <section id="mbbs_abroad" className="py-4 bg-light py-4" > 
                <div className="container">

                    <h3 className="fw-bold my-3">
                    ELIGIBILITY CRITERIA FOR 
                        <span className="text-primary">&nbsp; MBBS IN RUSSIA</span>
                    </h3>
                    <Table striped bordered hover className="mt-3">
                        <thead>
                            <tr>
                                <th>CRITERIA</th>
                                <th>REQUIREMENTS</th>
                               

                            </tr>
                        </thead>
                        <tbody>
                            <tr>

                                <td>Academic Qualification</td>
                                <td>10+2 with a minimum 50 % score in PCB/PCM (Physics, Chemistry, and Biology) (Note- PCM is applicable from 2024.)</td>
                            </tr>
                            <tr>

                                <td>Age Requirement</td>
                                <td>Minimum 17 years of age by the 31st December of the admission year.</td>
                            </tr>
                            <tr>
                                <td>NEET Requirement </td>
                                <td>Minimum of 50% for the General/EWS category 40% for the Unreserved Category SC/ST and OBC.</td>
                               
                              
                            </tr>


                        </tbody>
                    </Table>
                    <br></br>

                </div>
            </section>
            <div className='container'>
            <h3 className="fw-bold my-3">
            REQUIRED DOCUMENTS FOR MBBS IN RUSSIA:
               
               </h3>
                <div className='row'>
                    <div className='col-md-12'>
                        <ul className='list-none mt-2'>
                            <li className='mt-1'><Bs1CircleFill />&nbsp; Pass certificates and mark sheets of classes 10th and 12th.</li>
                            <li className='mt-2 '> <Bs2CircleFill /> &nbsp; Health certificate/report</li>
                            <li className='mt-2 '> <Bs3CircleFill />&nbsp; Valid passport</li>
                            <li className='mt-2 '> <Bs3CircleFill />&nbsp; Birth certificate</li>
                            <li className='mt-2 '> <Bs3CircleFill />&nbsp; Passport-size photos</li>
                            <li  className='mt-2 '> <Bs3CircleFill />&nbsp;NEET scorecard
</li>
                            

                           
                            
                        </ul>
                    </div>

                </div>
            </div>
            <section id="mbbs_abroad" className="py-4 bg-light">
                <div className="container">

                    <h3 className="fw-bold my-3">
                    MBBS IN RUSSIA VS 
                        <span className="text-primary">&nbsp; MBBS IN INDIA</span>
                    </h3>
                    <Table striped bordered hover className="mt-3">
                        <thead className="bg-dark">
                            <tr>
                                <th className="text-white">FACTORS</th>
                                <th className="text-white">MBBS IN RUSSIA</th>
                                <th className="text-white">MBBS IN INDIA </th>
                               

                            </tr>
                        </thead>
                        <tbody>
                            <tr>

                                <th>Low tuition fees</th>
                                <td>INR 15-30 Lakhs</td>
                                <td>INR 50 lakhs - INR 1.5 crore for entire course, depending on the institution </td>
                            </tr>
                            <tr>
                                <th>No donation required</th>
                                <td>Russian universities require no donation.</td>
                                <td>Private medical colleges often require high donations in addition to tuition fees.</td>
                            </tr>
                            <tr>
                              <th>Less competitive admission </th>
                                <td>Only required qualifying NEET percentile score for admission.</td>
                                <td>Admission is based on the NEET score and cut off which goes very high.</td>
                               
                              
                            </tr>

                            <tr>
                              <th>Globally recognised education</th>
                                <td>Recognized by WHO and NMC, allowing students to practice globally after clearing licensing exams.</td>
                                <td>Indian degrees are highly recognized locally, but foreign practice may require additional steps.</td>
                               
                              
                            </tr>

                            <tr>
                              <th>Advanced infrastructure</th>
                                <td>Russian medical universities are known for well-equipped laboratories, research facilities, and advanced technology.</td>
                                <td>Many Indian colleges have excellent facilities, but overcrowding and limited seats can affect resources.</td>
                               
                              
                            </tr>
                            <tr>
                            <th>No language barrier</th>
                            <td>Many universities offer programs in English; Russian is only necessary for patient interactions.</td>
                            <td>Language is not a barrier in India, but English proficiency is required for all courses.</td>
                            </tr>
                            


                        </tbody>
                    </Table>
                    <br></br>

                </div>
            </section>

            <section>
              <div className="container py-3">

              
              <b>LICENSING EXAM FOR INDIAN STUDENTS WITH FOREIGN MBBS DEGREES: ELIGIBILITY AND PROCESS</b>
              <p>NExT stands for national exit exam and it's for getting a medical license. Here’s an overview of the NExt requirements and process:</p>
              <ul>
                <li><b>Purpose of NExT</b> - Licensing: NExT is used to qualify the foreign medical graduates who wish to practice medicine r in India.
                - Postgraduate Admission: NExT also serves as the entry exam for the Postgraduate Medical Courses and has replaced NEET PG.

</li>
<li>
  <b>Exam Structure</b>
  - NExT-1: A theoretical, multiple-choice question (MCQ) exam covering key medical subjects. This is designed to assess foundational medical knowledge.
  - NExT-2: A practical and clinical skills examination to evaluate hands-on competencies in real-world medical settings.

</li>
<li><b>Eligibility - </b> 
MBBS degree must be from an internationally recognised medical institute approved by NMC and other international medical bodies

</li>
<li><b>Passing Criteria</b>
<p>1. For NExT 1, getting 50% is mandatory.</p>
<p>2. For NExT 2, passing the practical skills is mandatory </p>
</li>
<li>
  <b>Internship Requirement - </b>
  After passing the NExt exam, one year internship is mandatory in an NMC recognised hospital.
</li>
<li>

<p><b>Registration and Licensing - </b> After clearing NExt (both parts) and the internship, the candidate is eligible to be able to practice medicine in India. </p>
The NExT exam aims to ensure a high and consistent standard of medical knowledge and clinical skills among all doctors practicing in India, creating a uniform pathway for both Indian and foreign-trained graduates.

</li>

              </ul>
              </div>
            </section>
            <hr></hr>
            <br></br>
            <div className="container">  
                <h3 className="fw-bold my-3">
                Top Universities in 
                        <span className="text-primary">&nbsp;  Russia</span>
                </h3>

                <div className="row">
              
                {russiaUniversities.map((data) => {
                                 return (
                                    <div className="col-md-4 mt-4">
                                    <Link to={`/university/${data.slug}`} key={data.slug}>
                                       <div className="items">
                                          <div className="card" id="icons-crd" style={{ width: '100%' }}>
                                             <img src={data.cover_image} className="card-img-top" loading="lazy" alt="NayeeDisha" />
                                             <span class="universitytype border-radius-5 text-white"> Private </span>
                                             <div className="card-body iconerss-card">
                                                <img src={data.logo} loading="lazy" alt="NayeeDisha" />
                                                <Link to={`/university/${data.slug}`}>
                                                   <h5 className="mt-1 text-transform">{data.name}</h5>
                                                </Link>
                                                <hr/>
                                                <div>
                                                   <div className='pt-2 college_part'>
                                                      <p className='para1' style={{ color: '#000' }}>Fee <span className='ugc' style={{ color: '#000' }}>: {data.total_budget ? data.total_budget : 'Not found'} </span></p>
                                                      <p className='para1'>Estd. Year <span className='ugc' style={{ color: '#000' }}>&nbsp;&nbsp;&nbsp;: {data.estb ? data.estb : 'Not found'} </span></p>
                                                   </div>
                     
                                                </div>
                                                <div>
                                                   <div className='college_part'>
                                                   <p className='para1'>Country <span className='ugc' style={{ color: '#000' }}> &nbsp;&nbsp;&nbsp;: {data.country ? data.country : 'Not found'} </span></p>
                                                   {data.ranking && (
    <p className='para1' style={{ color: '#000' }}>
        World Rank<span className='ugc' style={{ color: '#000' }}> : {data.ranking}</span>
    </p>
)}

                                                   </div>
                                                   <div className='d-flex justify-content-between mt-3'>
                                                      <Button type="button" className="btn btn-primary px-4 p-2 " data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo" id='btnr1'>
                                                         Apply Now &nbsp;&nbsp;&nbsp;
                                                         <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M5.78009 4.45975C5.9209 4.6006 6 4.79161 6 4.99077C6 5.18993 5.9209 5.38094 5.78009 5.52179L1.53118 9.7707C1.46189 9.84244 1.37902 9.89966 1.28738 9.93902C1.19574 9.97839 1.09719 9.99911 0.997458 9.99997C0.897728 10.0008 0.798825 9.98183 0.706519 9.94407C0.614213 9.9063 0.530352 9.85053 0.45983 9.78001C0.389308 9.70949 0.333538 9.62563 0.295773 9.53332C0.258007 9.44102 0.239003 9.34211 0.23987 9.24238C0.240736 9.14266 0.261456 9.0441 0.30082 8.95246C0.340184 8.86083 0.397403 8.77795 0.46914 8.70866L4.18703 4.99077L0.469139 1.27288C0.332322 1.13122 0.256617 0.941496 0.258328 0.744563C0.260039 0.54763 0.33903 0.359248 0.478288 0.219989C0.617547 0.0807309 0.805929 0.001739 1.00286 2.80934e-05C1.1998 -0.00168282 1.38952 0.0740227 1.53118 0.210839L5.78009 4.45975Z" fill="white" />
                                                         </svg>
                                                      </Button>
                                                      <a href={`/university/${data.slug}`}>
                                                         <Button className="btn" variant="outline-success">Read More</Button>
                                                      </a>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </Link>
                                    </div>
                                 );
                              })}
                


                </div>
            </div>

            <br></br>
        </>
    );
};

export default Mbbsinrussia;

