import { React, useEffect, useState, Component, Fragment, useRef } from "react";
import { Link } from "react-router-dom";
import student from '../img/Student.png';
import counselling from '../img/counselling.jpg';
import studenter from '../img/Studenter.svg';
import countries from '../img/global.png';
// import worlwide from '../img/global.png';
import { CiCircleList } from "react-icons/ci";
import { FaClipboardList } from "react-icons/fa";
import { CiLock } from "react-icons/ci";
import { FaLock } from "react-icons/fa";
import mbbsabroad from '../img/mbbs-abroad.svg';
import universities_logo from '../../img/russiasqr.svg'
import mbbsindia from '../img/Mbbsindia.svg';
import Studyabroad from '../img/Studyabroad.svg';
import Engineering from '../img/engineering.svg';
import { IoArrowForwardCircle } from "react-icons/io5";
import { IoIosArrowRoundForward } from "react-icons/io";
import BussinessManagement from '../img/BussinessManagement.svg'
import worlwide from '../img/university.png';
import aniimg from '../../img/anitrans.svg';
import stu from '../img/stu.svg';
import one from '../img/one.webp';
import two from '../img/onee.svg';
import theee from '../img/three.webp';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import mobileone from '../img/mobile_one.webp';
import mobiletwo from '../img/mobile_two.webp';

import China from '../img/China.svg';
import India from '../img/India.svg';
import Kazakhstan from '../img/Kazakhstan.svg';
import Bangladesh from '../img/Bangladesh.svg';
import Kyrgyzstan from '../img/Kyrgyzstan.svg';
import Philippines from '../img/Philippines.svg';
import Nepal from '../img/Nepal.svg';
import Belarus from '../img/Belarus.svg';
import Egypt from '../img/Egypt.svg';
import Georgia from '../img/Georgia.svg';
import Germany from '../img/Germany.svg';
import Italy from '../img/Italy.svg';
import Malaysia from '../img/Malaysia.svg';
import Romania from '../img/Romania.svg';
import Poland from '../img/poland.svg';
import Russia from '../img/Russia.svg';
// import { GiSpellBook } from "react-icons/gi";
import { GiPassport } from "react-icons/gi";
import mobilefiestbanner from '../img/nayee_phone.webp';
import tabviewone from '../img/Darkdisha_bannertab.webp';
import tabviewtwo from '../img/career_bannertab.webp';



import mobilecareerconsulting from '../img/careerbanner_phone.webp';



import { TiPlaneOutline } from "react-icons/ti";
import { FaPlaneDeparture } from "react-icons/fa6"

import { GiPlaneOutline } from "react-icons/gi";



import { FaWpforms } from "react-icons/fa";
import { FaUniversity } from "react-icons/fa";

import Counselling from '../img/Rectangle2.png';

import Animation from '../img/Animation.gif';
import { FaStar } from "react-icons/fa";
import { FaRegStarHalfStroke } from "react-icons/fa6";
import baneercoun from '../img/disha_career.webp';
import DarkBanner from '../img/disha_dark_banner.webp';
import huntexam from '../img/nayee_scholarship.webp';
import hunexammobile from '../img/nayee_scholarship_phone.webp';
// import consulting from '../img/consulting.svg';
import Carousel from 'react-bootstrap/Carousel';
import { FaComments } from "react-icons/fa";
import consulting from '../img/discussion.png';
import { GoPlusCircle } from "react-icons/go";
import woman from '../img/woman.png';
import man from '../img/man.png';
import bi1 from '../img/bi-1.png';
import bi2 from '../img/bi-2.png';
import bi3 from '../img/bi-3.png';
import bi4 from '../img/bi-4.png';
import bi5 from '../img/bi-5.png';
import bi6 from '../img/bi-6.png';
import bi7 from '../img/georgia.png';
import bi8 from '../img/bi-8.png';
import biAni from '../img/kazz.png'
import { GrFormNextLink } from "react-icons/gr";
import { GrFormPreviousLink } from "react-icons/gr";
import { GrFormNext } from "react-icons/gr";
import student2 from '../img/student2.svg';
import worldwide2 from '../img/worldwide2.svg';
import countries2 from '../img/countries2.svg';
import consulting2 from '../img/consulting2.svg';
import copyrimg from '../img/copyr-img.png'
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import OwlCarousel from "react-owl-carousel";
import "../../../node_modules/owl.carousel/dist/assets/owl.carousel.css";
import "../../../node_modules/owl.carousel/dist/assets/owl.theme.default.css";
import Button from 'react-bootstrap/Button';
import { DropDownCoursesData } from "../../data/dropdownData";
import { useHistory } from "react-router-dom";
import apiUrl from '../../setting/apiConfig';
import { MagnifyingGlass } from 'react-loader-spinner'
// import { Pagination, Navigation } from 'swiper/modules';
import { Helmet } from "react-helmet";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import News from "../news";
import { GrView } from "react-icons/gr";
import { IoGitCompareOutline } from "react-icons/io5";
import { MdOutlineReadMore } from "react-icons/md";
import { FaTimes } from 'react-icons/fa';
import { useComparison } from '../compare_colleges/CompareContext/CompareContext';
import CompareCollege from '../comparepage/index'
import CountUp from 'react-countup';
import Marquee from "react-fast-marquee";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import Swal from 'sweetalert2';
import PhoneInput from "react-phone-input-2";
import { FaBalanceScaleLeft } from "react-icons/fa";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import endbannr from '../img/Enroll-baneer02.webp';

const Home = () => {
   const history = useHistory();
   const router = useHistory()
   const [subCourse, setSubCourse] = useState([]);
   const [special, setSpecial] = useState([]);
   const [redUrl, setUrl] = useState('');
   const formRef = useRef(null); // Form reference for resetting
   const [phoneNumber, setPhoneNumber] = useState(''); // State for phone input
   const [loading, setLoading] = useState(false);
   const [successMessage, setSuccessMessage] = useState(null);
   const [errorMessage, setErrorMessage] = useState(null);
   const [courses, setCourses] = useState([]);
   const [selectedProgram, setSelectedProgram] = useState("");

   const getCookie = (name) => {
      let value = `; ${document.cookie}`;
      let parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
   }
   const cache = {
      blogdetails: null,
   };
   const handleSubcourse = (val) => {
      const sub = DropDownCoursesData.find(x => x.value === val);
      if (sub) {
         setSubCourse(sub.subCourses)
      }
   }
   const handleSpecial = (val) => {
      const sub = subCourse.length ? subCourse.find(x => x.value === val) : undefined;
      // console.log('first---------------->', sub)
      if (sub) {
         setSpecial(sub.specialization)
      }
   }

   const submitForm = async (event) => {
      event.preventDefault();

      const formData = new FormData(event.target);
      formData.append('current_url', getCookie("url"));
      formData.append('campaign', 'mbbs-in-russia-from-main-page');

      try {
         setLoading(true);

         // Convert FormData to a plain object
         const data = {};
         formData.forEach((value, key) => {
            data[key] = value;
         });

         const response = await fetch('https://webcrmapi.nayeedisha.in/api/landingpage-leads', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
               'Content-Type': 'application/json',
            },
         });

         const responseData = await response.json();

         if (!response.ok) {
            // Check if responseData.error is an object and handle it accordingly
            let errorText = 'Failed to submit form';
            if (responseData.error) {
               if (typeof responseData.error === 'object') {
                  errorText = Object.values(responseData.error).flat().join(', ');
               } else {
                  errorText = responseData.error;
               }
            }
            throw new Error(errorText);
         }

         setLoading(false);
         setSuccessMessage(responseData.message);
         setErrorMessage(null);

         formRef.current.reset(); // Reset the form

         Swal.fire({
            title: "Success!",
            text: responseData.message,
            icon: "success",
         }).then(() => {
            if (window.gtag) {
               console.log("Logging Google Analytics event...");
               window.gtag('event', 'submit', {
                  'event_category': 'Form',
                  'event_label': 'Admission Form',
                  'value': 'text_success'
               });
               console.log("Google Analytics event submitted.");
            } else {
               console.warn("gtag function is not available.");
            }

            setTimeout(() => {
               setSuccessMessage(null);
            }, 5000);
            setPhoneNumber(''); // Clear phone input
            formRef.current.reset(); // Reset the entire form
         });
      } catch (error) {
         console.error("Error during form submission:", error);
         setLoading(false);
         setSuccessMessage(null);

         const errorText = error.message || 'Failed to submit form';

         setErrorMessage(errorText);

         Swal.fire({
            title: "Error!",
            text: errorText,
            icon: "error",
            showConfirmButton: false,
            timer: 1500
         });
      }
   };

   const skeletonArray = Array(4).fill(0);
   const isDesktop = useMediaQuery({ minWidth: 769 });

   const options = {

      loop: true,
      margin: 10,
      items: 1,
      autoplay: true,
      responsive: {
         0: {
            items: 1,
            nav: true,
            dots: false,

         },
         600: {
            items: 2,
            dots: false,
         },
         1000: {
            items: 3,
            nav: false,
            dots: false,
            animateOut: 'fadeOut'
         },
      },
   };
   let carouselRef = null;

   const handleNext = () => {
      carouselRef.next();
   };

   const handlePrev = () => {
      carouselRef.prev();
   };
   const optionstest = {
      loop: true,
      margin: 10,
      items: 1,
      autoplay: true,
      responsive: {
         0: {
            items: 1,
            nav: true,

         },
         600: {
            items: 3,
         },
         1000: {
            items: 3,
         },
      },
   };

   // silk
   const settings = {
      dots: true, // Show navigation dots
      infinite: true, // Enable infinite scrolling
      speed: 500, // Transition speed in ms
      slidesToShow: 4, // Number of slides to show at a time
      slidesToScroll: 1, // Number of slides to scroll per swipe
      autoplay: true, // Auto scroll
      autoplaySpeed: 3000, // Auto scroll speed in ms
      arrows: true, // Show left and right arrows
      responsive: [
         {
            breakpoint: 1024, // For devices with width <= 1024px
            settings: {
               slidesToShow: 3,
               slidesToScroll: 1,
               infinite: true,
               arrows: true,
            },
         },
         {
            breakpoint: 768, // For devices with width <= 768px
            settings: {
               slidesToShow: 2,
               slidesToScroll: 1,
               arrows: true, // Hide arrows for smaller screens
            },
         },
         {
            breakpoint: 480, // For devices with width <= 480px
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
               arrows: true, // Hide arrows for extra small screens
            },
         },
      ],
   };

   const data = {
      ugcapproved: 1 // Assuming ugcapproved holds the value
   };

   const [universities, setUniversities] = useState([]);
   const [newsdetails, setNewsdetails] = useState([]);
   const [testimonial, setTestimonial] = useState([]);
   const [exams, setExams] = useState([]);
   const [isLoading, setIsLoading] = useState(true);
   const [blogdetails, setBlogdetails] = useState([]);

   // compare college const [colleges, setColleges] = useState([]);
   const [colleges, setColleges] = useState([]);
   const [showDropdown, setShowDropdown] = useState([false, false, false]);
   const [selectedCollege, setSelectedCollege] = useState([null, null, null]);
   const [searchQuery, setSearchQuery] = useState(['', '', '']);
   const { setComparisonData } = useComparison();
   useEffect(() => {
      fetch('https://webcrmapi.nayeedisha.in/api/course-details')
         .then(response => response.json())
         .then(data => setColleges(data))
         .catch(error => console.error('Error fetching data:', error));
   }, []);

   const handleAddCollegeClick = (index) => {
      const newShowDropdown = showDropdown.map((value, idx) => (idx === index ? !value : value));
      setShowDropdown(newShowDropdown);
   };

   const handleCollegeChange = (index, college) => {
      if (selectedCollege.includes(college)) {
         alert('This University has already been selected.');
         return;
      }
      const newSelectedCollege = selectedCollege.map((value, idx) => (idx === index ? college : value));
      setSelectedCollege(newSelectedCollege);
      const newShowDropdown = showDropdown.map((value, idx) => (idx === index ? false : value));
      setShowDropdown(newShowDropdown);
      setSearchQuery(searchQuery.map((value, idx) => (idx === index ? '' : value)));
   };

   const handleRemoveCollege = (index) => {
      const newShowDropdown = showDropdown.map((value, idx) => (idx === index ? false : value));
      const newSelectedCollege = selectedCollege.map((value, idx) => (idx === index ? null : value));
      setShowDropdown(newShowDropdown);
      setSelectedCollege(newSelectedCollege);
   };

   const handleSearchChange = (index, query) => {
      const newSearchQuery = searchQuery.map((value, idx) => (idx === index ? query : value));
      setSearchQuery(newSearchQuery);
   };

   const filteredColleges = (index) => {
      return colleges.filter(college =>
         college.name.toLowerCase().includes(searchQuery[index].toLowerCase())
      );
   };

   const handleCompareNow = () => {
      const selectedColleges = selectedCollege.filter(college => college);

      if (selectedColleges.length < 2) {
         alert('Please select at least two colleges for comparison.');
         return;
      }

      const selectedUids = selectedColleges.map(college => college.uid);
      const collegeNames = selectedColleges.map(college => college.name).join('-vs-');

      const data = {
         university_ids: selectedUids
      };

      const requestOptions = {
         method: 'POST',
         headers: {
            'Content-Type': 'application/json'
         },
         body: JSON.stringify(data)
      };

      fetch('https://webcrmapi.nayeedisha.in/api/compare-universities', requestOptions)
         .then(response => response.json())
         .then(result => {
            console.log('API Response:', result);

            // Save the comparison data to local storage
            localStorage.setItem('comparisonData', JSON.stringify(result));

            // Set the comparison data in context
            setComparisonData(result);

            // Construct the URL based on college names
            const urlSlug = collegeNames.toLowerCase().replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-]/g, '');
            history.push(`/compare/${urlSlug}`);
         })
         .catch(error => {
            console.error('Error comparing universities:', error);
         });
   };

   // close

   useEffect(() => {
      fetch('https://webcrmapi.nayeedisha.in/api/blog-details')

         .then((response) => response.json())
         .then((data) => {
            // Assuming 'results' contains the array of universities
            setBlogdetails(data);
            setIsLoading(false);

         })
         .catch((error) => {
            setIsLoading(false);
            console.error('Error fetching data:', error);
         });
   }, []);
   useEffect(() => {
      const fetchData = async () => {
         try {
            setIsLoading(true);

            const universityResponse = await fetch(apiUrl + 'university-details');
            const universityData = await universityResponse.json();
            setUniversities(universityData);


            const newsResponse = await fetch(apiUrl + 'news-details');
            const newsData = await newsResponse.json();
            setNewsdetails(newsData);


            const testimonialResponse = await fetch(apiUrl + 'testimonial-details');
            const testimonialData = await testimonialResponse.json();
            setTestimonial(testimonialData);


            const examsResponse = await fetch(apiUrl + 'exam-details');
            const examsData = await examsResponse.json();
            setExams(examsData);





            setIsLoading(false);
         } catch (error) {
            setIsLoading(false);
            console.error('Error fetching data:', error);
         }
      };

      fetchData();
   }, []);



   // fatch course 

   // Fetch data from the API
   useEffect(() => {
      const fetchCourses = async () => {
         try {
            const response = await fetch("https://webcrmapi.nayeedisha.in/api/course-details");
            const data = await response.json();
            setCourses(data); // Assuming data is an array of courses
         } catch (error) {
            console.error("Error fetching courses:", error);
         }
      };

      fetchCourses();
   }, []);

   const handleChange = (event) => {
      setSelectedProgram(event.target.value);
   };




   const redirectHandler = () => router.push(redUrl);

   const style = {
      maxWidth: '455px',
   };

   const [key, setKey] = useState('home');

   return (
      <Fragment>

         <div className="application">
            <Helmet>
               <meta charSet="utf-8" />
               <title>Nayee Disha: Top Colleges, Universities in Abroad and India </title>
               <meta name="description" content="Explore top colleges and universities in India and abroad with NayeeDisha. Expert guidance for admissions and career advice. Contact 8860031900 for assistance!" />
               <meta name="keywords"
                  content="Top Colleges in India and abroad, Abroad  education Consultants, MBBS Abroad Consultancy, Best Abroad Consultancy in India, Best university for MBBS Abroad, Study MBBS 2024-25" />
               <link rel="canonical" href="https://nayeedisha.in" />
            </Helmet>

         </div>

         <Carousel data-bs-theme="dark" className="carousel-inner carousel_inner_ani">
            <Carousel.Item>

               <picture  data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                data-bs-whatever="@mdo">
                  {/* Mobile Image */}
                  <source media="(max-width: 576px)" loading="lazy" srcSet={mobilefiestbanner} />
                  {/* Tablet Image */}
                  <source media="(max-width: 992px)" loading="lazy" srcSet={tabviewone} />
                  {/* Desktop Image */}
                  <img
                     className="d-block w-100"
                     src={DarkBanner} // Fallback image for larger screens
                     alt="Responsive slide"
                     loading="lazy"
                  />
               </picture>



            </Carousel.Item>
            <Carousel.Item>


               <picture  data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                data-bs-whatever="@mdo">
                  {/* Mobile Image */}
                  <source media="(max-width: 576px)" loading="lazy" srcSet={mobilecareerconsulting} />
                  {/* Tablet Image */}
                  <source media="(max-width: 992px)" loading="lazy" srcSet={tabviewtwo} />
                  {/* Desktop Image */}
                  <img
                     className="d-block w-100"
                     src={baneercoun} // Fallback image for larger screens
                     alt="Responsive slide"
                     loading="lazy"
                  />
               </picture>


            </Carousel.Item>

         </Carousel>
         <div className="section2 pt-5 pb-4">
            <div className="container mobilesection-hide_new">
               <div className="d-flex  justify-content-around boxess">
                  {/* <div class="box1 d-flex col-lg-3 col-md-6 col-sm-12 col-3">
                     <div className="imr">
                        <img src={stu} alt="NayeeDisha" />
                     </div>
                     &nbsp;&nbsp;&nbsp;
                     <div>
                        <h5>50+ Research</h5>
                        <p>Staff</p>
                     </div>
                  </div> */}
                  <div class="box1 justify-content-center d-flex col-lg-4 col-md-6 col-sm-12 col-3">
                     <div className="imr">
                        <img src={consulting} loading="lazy" alt="NayeeDisha" />
                     </div>
                     &nbsp;&nbsp;&nbsp;
                     <div>

                        <h5> <CountUp end={207996} />+ Students</h5>
                        <p>Counselled</p>
                     </div>
                  </div>
                  <div class="box1 justify-content-center d-flex col-lg-4 col-md-6 col-sm-12 col-3">
                     <div className="imrun">

                        <img src={worlwide} loading="lazy" alt="NayeeDisha" />
                     </div>
                     &nbsp;&nbsp;&nbsp;
                     <div>
                        <h5> <CountUp end={450} />+ University</h5>
                        <p>Tie-ups</p>
                     </div>
                  </div>
                  <div class="box1 justify-content-center d-flex col-lg-4 col-md-6 col-sm-12 col-3">
                     <div className="imr">
                        <img src={countries} loading="lazy" alt="NayeeDisha" />
                     </div>
                     &nbsp;&nbsp;&nbsp;
                     <div>
                        <h5>Reach in <CountUp end={35} />+ </h5>
                        <p className="p-head"> Countries </p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="adOn_homePartners bg-light">
            <div className="container adOn_CarPosition position-absolute">
               <div className="row">
                  <div className="col"></div>
                  <div className="col-md-12 col-lg-9">
                     <div className="adOn_compareWrap">
                        <div className="adOn_compareBadgeOuter">

                        </div>
                        <div className="adOn_compareInner slick-initialized slick-slider">
                           <h5 className="fw-700">Your Personalized University Finder</h5>
                           <div className="row">
                              <div className="col-md-3 mt-3">
                                 {/* <input type="text" className="form-control" placeholder="Select Program" /> */}
                                 <select
                                    className="form-control"
                                    name="Select Program"
                                    id="select_program"
                                    value={selectedProgram}
                                    onChange={handleChange}
                                 >
                                    {/* <option value="">Select Course</option> */}
                                    {/* {courses.map((course) => (
                                       <option key={course.name} value={course.name}>
                                          {course.name}
                                       </option>
                                    ))} */}
                                     <option >Select Dream Course</option>
                                     <option value="Bachelor of Medicine Bachelor of Surgery (MBBS)">Bachelor of Medicine Bachelor of Surgery (MBBS)</option>

                                    <option value="Bachelor of Dental Surgery.">Bachelor of Dental Surgery.</option>
                                   
                                    
                                 </select>

                              </div>
                              <div className="col-md-3 mt-3">
                                 {/* <input type="text" className="form-control" placeholder="Select Degree" /> */}
                                 <select className="form-control" name="Select Dream Country" id="Select Dream Country">
                                    <option >Select Dream Country</option>
                                    <option value="Russia">Russia</option>
                                    <option value="India">India</option>
                                    <option value="Nepal">Nepal</option>
                                    <option values="Philippines">Philippines</option>
                                    <option value="Kyrgyzstan" >Kyrgyzstan</option>
                                    <option value="China">China</option>
                                    <option value="Bangladesh">Bangladesh </option>

                                 </select>
                              </div>
                              <div className="col-md-3 mt-3">
                                 {/* <input type="text" className="form-control" placeholder="Select Dream Country" /> */}
                                 <select className="form-control" name="Select Dream Country" id="Select Dream Country">
                                    <option >Select Your Budget</option>
                                    <option value="India">500000 - 1000000 INR</option>
                                    <option value="Russia">100000 - 1500000 INR</option>
                                    <option value="Nepal">1500000 - 2000000 INR</option>


                                 </select>
                              </div>

                             <div className="col-md-3 mt-3">
                             <a href="study-abroad">
                                 <input  type="submit" class="btn btn-outline-primary" placeholder="Search" /></a>
                              </div>
                           </div>
                        </div>


                     </div>
                  </div>
                  <div className="col"></div>
               </div>
            </div>

         </div>



         {/* SECTION1 */}
         {/* <div className="banner-section-copy ">
            <div className="container">

               <div className="row pt-5  pb-5 ">
                  <div className="col-md-5 " >
                     <div id="fonter">
                        <h5>Study Medicine Abroad</h5>
                        <p>At low Cost in <span className="cpr-2">World Ranked Universities! </span></p>
                        <p id="cpr-3"> Honest Counselling &nbsp;
                           <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none" className="m-">
                              <circle cx="4" cy="4" r="4" fill="#FFAA01" />

                           </svg> &nbsp;
                           University Selection &nbsp;
                           <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                              <circle cx="4" cy="4" r="4" fill="#FFAA01" />
                           </svg> &nbsp;
                           Admission
                        </p>
                     </div>
                     <div className="mt-4 d-flex justify-content-between flexyr">
                        <div>
                           <img src={bi1} width="" height="" alt="NayeeDisha" />
                           <p>India</p>
                        </div>
                        <div>
                           <img src={bi2} width="" height="" alt="NayeeDisha" />
                           <p>Russia</p>
                        </div>
                        <div>
                           <img src={bi3} width="" height="" alt="NayeeDisha" />
                           <p>Kyrgyzstan</p>
                        </div>
                        <div>
                           <img src={bi4} width="" height="" alt="NayeeDisha" />
                           <p>China</p>
                        </div>
                     </div>
                     <div className="mt-4 d-flex justify-content-between flexyr">
                        <div>
                           <img src={biAni} width="" height="" alt="NayeeDisha" />
                           <p>Kazakhstan</p>
                        </div>
                        <div>
                           <img src={bi6} width="" height="" alt="NayeeDisha" />
                           <p>Bangladesh</p>
                        </div>
                        <div>
                           <img src={bi7} width="" height="" alt="NayeeDisha" style={{
                              width: '88px',
                              borderRadius: '6px',
                              border: '2px solid #ccc'
                           }} />
                           <p>Georgia</p>
                        </div>
                        <div>
                           <img src={bi8} width="" height="" alt="NayeeDisha" />
                           <p>Nepal</p>
                        </div>
                     </div>
                     <div className="text-center mt-4">
                        <button type="button" className="btn btn-primary px-4" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo" id='btnrs1'>
                           Register Now &nbsp;&nbsp; <IoIosArrowRoundForward />&nbsp; </button>
                     </div>
                  </div>

                  <div className="col-lg-4 col-md-7 offset-md-3">
                     <div className="banner-copy-form p-4 mt-2">
                        <h5>
                           Get one to one free counselling
                        </h5>
                        <p>Free forever. No payment needed.</p>
                        <form ref={formRef} className="align" onSubmit={submitForm} >
                           <div className="mb-3">
                              <label className="form-label">
                                 Full Name <span className="text-danger">*</span>
                              </label>
                              <input
                                 type="text"
                                 name="name"
                                 className="form-control"
                                 placeholder="Full Name"
                                 required
                              />

                           </div>
                           <div className="mb-3">
                              <label className="form-label">
                                 Phone <span className="text-danger">*</span>
                              </label>
                              <PhoneInput
                                 inputProps={{ name: 'phone_number', required: true, autoFocus: false }}
                                 country={'in'}
                                 value={phoneNumber}
                                 onChange={(phone) => setPhoneNumber(phone)} // Handle phone number state
                              /> </div>
                           <div className="mb-3">
                              <label className="form-label">
                                 Select State <span className="text-danger">*</span>
                              </label>
                              <select
                                 name="state"
                                 className="form-control"

                              >
                                 <option value="">Please Select Preferred State</option>
                                 <option value="Delhi NCR">Delhi NCR</option>
                                 <option value="Uttar Pradesh">Uttar Pradesh</option>
                                 <option value="Andhra Pradesh">Andhra Pradesh</option>
                                 <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                 <option value="Assam">Assam</option>
                                 <option value="Bihar">Bihar</option>
                                 <option value="Chhattisgarh">Chhattisgarh</option>
                                 <option value="Gujarat">Gujarat</option>
                                 <option value="Haryana">Haryana</option>
                                 <option value="Himachal Pradesh">Himachal Pradesh</option>
                                 <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                                 <option value="Goa">Goa</option>
                                 <option value="Jharkhand">Jharkhand</option>
                                 <option value="Karnataka">Karnataka</option>
                                 <option value="Kerala">Kerala</option>
                                 <option value="Madhya Pradesh">Madhya Pradesh</option>
                                 <option value="Maharashtra">Maharashtra</option>
                                 <option value="Manipur">Manipur</option>
                                 <option value="Meghalaya">Meghalaya</option>
                                 <option value="Mizoram">Mizoram</option>
                                 <option value="Nagaland">Nagaland</option>
                                 <option value="Odisha">Odisha</option>
                                 <option value="Punjab">Punjab</option>
                                 <option value="Rajasthan">Rajasthan</option>
                                 <option value="Sikkim">Sikkim</option>
                                 <option value="Tamil Nadu">Tamil Nadu</option>
                                 <option value="Telangana">Telangana</option>
                                 <option value="Tripura">Tripura</option>
                                 <option value="Uttarakhand">Uttarakhand</option>
                                 <option value="Uttar Pradesh">Uttar Pradesh</option>
                                 <option value="West Bengal">West Bengal</option>
                                 <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                                 <option value="Chandigarh">Chandigarh</option>
                                 <option value="Dadra and Nagar Haveli">Dadra and Nagar Haveli</option>
                                 <option value="Daman and Diu">Daman and Diu</option>
                                 <option value="Delhi">Delhi</option>
                                 <option value="Lakshadweep">Lakshadweep</option>
                                 <option value="Puducherry">Puducherry</option>
                              </select>

                           </div>
                           <div className="cp-but mt-4">
                              <button type="submit" className="btn btn-primary">Submit</button>
                           </div>
                           {successMessage && <p className="text-success">{successMessage}</p>}
                           {errorMessage && <p className="text-danger">{errorMessage}</p>}
                        </form>
                     </div>
                  </div>
               </div>
            </div>
         </div> */}
         {/* SECTION2 */}





         <div className="section2 pt-5 pb-4 d-none">
            <div className="container mobilesection-hide">
               <div className="d-flex  justify-content-around boxess">
                  {/* <div class="box1 d-flex col-lg-3 col-md-6 col-sm-12 col-3">
                     <div className="imr">
                        <img src={stu} alt="NayeeDisha" />
                     </div>
                     &nbsp;&nbsp;&nbsp;
                     <div>
                        <h5>50+ Research</h5>
                        <p>Staff</p>
                     </div>
                  </div> */}
                  <div class="box1 justify-content-center d-flex col-lg-4 col-md-6 col-sm-12 col-3">
                     <div className="imr">
                        <img src={consulting} loading="lazy" alt="NayeeDisha" />
                     </div>
                     &nbsp;&nbsp;&nbsp;
                     <div>

                        <h5> <CountUp end={207996} />+ Students</h5>
                        <p>Counselled</p>
                     </div>
                  </div>
                  <div class="box1 justify-content-center d-flex col-lg-4 col-md-6 col-sm-12 col-3">
                     <div className="imrun">

                        <img src={worlwide} loading="lazy" alt="NayeeDisha" />
                     </div>
                     &nbsp;&nbsp;&nbsp;
                     <div>
                        <h5> <CountUp end={450} />+ University</h5>
                        <p>Tie-ups</p>
                     </div>
                  </div>
                  <div class="box1 justify-content-center d-flex col-lg-4 col-md-6 col-sm-12 col-3">
                     <div className="imr">
                        <img src={countries} loading="lazy" alt="NayeeDisha" />
                     </div>
                     &nbsp;&nbsp;&nbsp;
                     <div>
                        <h5>Reach in <CountUp end={35} />+ </h5>
                        <p className="p-head"> Countries </p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         {/* END SECTION2 */}
         {/* mobile section */}
         {/* designing for mobile section */}
         
         {/* end mobile section */}

         {/* section2-copy */}
         <br></br>
         <div className="container">
            <div className="row mb-4">
               <div className="col-12">
                  <h1 className="h2 fw-bold my-3 text-center">
                     Top Countries,
                     <span className="text-primary">&nbsp;Unlimited Opportunities</span>
                  </h1>
               </div>
            </div>
            <div className="row g-3 mb-3 justify-content-center ">
               <div className="col-lg-2 col-md-3 col-6">
                  <div className="align-items-center justify-content-center ">
                   
                        <a href="/mbbs-in-india">
                           <img className="adOn_homePartner img-fluid" alt="Chandigarh University Online" loading="lazy" srcset={India} src={India} class="w-100" />
                        </a>
                 
                  </div>
               </div>
               <div className="col-lg-2 col-md-3 col-6">
                  <div className="align-items-center justify-content-center">
                   
                        <a href="/mbbs-in-russia">
                           <img className="adOn_homePartner img-fluid" alt="Chandigarh University Online" loading="lazy" srcset={Russia} src={Russia} />
                        </a>
                     
                  </div>
               </div>
               <div className="col-lg-2 col-md-3 col-6">
                  <div className="align-items-center justify-content-center">
                     
                  <a href="#">
                           <img className="adOn_homePartner img-fluid" alt="Chandigarh University Online" loading="lazy" srcset={China} src={China} />
                        </a>
                     
                  </div>
               </div>
               <div className="col-lg-2 col-md-3 col-6">
                  <div className="align-items-center justify-content-center">
                    
                  <a href="#">
                           <img className="adOn_homePartner img-fluid" alt="Chandigarh University Online" loading="lazy" srcset={Kazakhstan} src={Kazakhstan} />
                        </a>
                  
                  </div>
               </div>
               <div className="col-lg-2 col-md-3 col-6">
                  <div className="align-items-center justify-content-center ">
                   
                  <a href="#">
                           <img className="adOn_homePartner img-fluid" alt="Chandigarh University Online" loading="lazy" srcset={Kyrgyzstan} src={Kyrgyzstan} />
                        </a>
                 
                  </div>
               </div>
               <div className="col-lg-2 col-md-3 col-6">
                  <div className="align-items-center justify-content-center ">
                   
                  <a href="#">
                           <img className="adOn_homePartner img-fluid" alt="Chandigarh University Online" loading="lazy" srcset={Poland} src={Poland} />
                        </a>
                     
                  </div>
               </div>
            
        
               <div className="col-lg-2 col-md-3 col-6">
                  <div className="align-items-center justify-content-center ">
                  
                  <a href="#">
                           <img className="adOn_homePartner img-fluid" alt="Chandigarh University Online" loading="lazy" srcset={Nepal} src={Nepal} />
                        </a>
                    
                  </div>
               </div>
               <div className="col-lg-2 col-md-3 col-6">
                  <div className="align-items-center justify-content-center">
                     
                  <a href="#">
                           <img className="adOn_homePartner img-fluid" alt="Chandigarh University Online" loading="lazy" srcset={Belarus} src={Belarus} />
                        </a>
                  
                  </div>
               </div>
               <div className="col-lg-2 col-md-3 col-6">
                  <div className="align-items-center justify-content-center">
                    
                  <a href="#">
                           <img className="adOn_homePartner img-fluid" alt="Chandigarh University Online" loading="lazy" srcset={Egypt} src={Egypt} />
                        </a>
                    
                  </div>
               </div>
               <div className="col-lg-2 col-md-3 col-6">
                  <div className=" align-items-center justify-content-center ">
                   
                  <a href="#">
                           <img className="adOn_homePartner img-fluid" alt="Chandigarh University Online" loading="lazy" srcset={Georgia} src={Georgia} />
                        </a>
                  
                  </div>
               </div>
               <div className="col-lg-2 col-md-3 col-6">
                  <div className="align-items-center justify-content-center ">
                   
                  <a href="#">
                           <img className="adOn_homePartner img-fluid" alt="Chandigarh University Online" loading="lazy" srcset={Germany} src={Germany} />
                        </a>
                     
                  </div>
               </div>
               <div className="col-lg-2 col-md-3 col-6">
                  <div className=" align-items-center justify-content-center ">
   
                  <a href="#">
                           <img className="adOn_homePartner img-fluid" alt="Chandigarh University Online" loading="lazy" srcset={Italy} src={Italy} />
                        </a>
                  
                  </div>
               </div>
               <div className="col-lg-2 col-md-3 col-6">
                  <div className=" align-items-center justify-content-center ">
                   
                  <a href="#">
                           <img className="adOn_homePartner img-fluid" alt="Chandigarh University Online"  loading="lazy" srcset={Malaysia} src={Malaysia} />
                        </a>
                   
                  </div>
               </div>
               <div className="col-lg-2 col-md-3 col-6">
                  <div className=" align-items-center justify-content-center">
                    
                  <a href="#">
                           <img className="adOn_homePartner img-fluid" alt="Chandigarh University Online"  loading="lazy" srcset={Romania} src={Romania} />
                        </a>
                   
                  </div>
               </div>

               <div className="col-lg-2 col-md-3 col-6">
                  <div className=" align-items-center justify-content-center">
                    
                  <a href="#">
                           <img className="adOn_homePartner img-fluid" alt="Chandigarh University Online"  loading="lazy" srcset={Bangladesh} src={Bangladesh} />
                        </a>
                   
                  </div>
               </div>
               
       

            </div>
<br/>
            <div className="row justify-content-center">
               <div className="col-lg-3 col-md-6 col-12 text-center">
               <a href="/study-abroad-countries" > <button className="btn btn-outline-primary" data-bs-toggle="modal" data-bs-target="#allUni">
                     See All Countries
                  </button></a>
               </div>
            </div>
            <br></br>
         </div>

         <section className="py-70 bg-light">
            <div className="container">
               <div className="row align-items-center justify-content-between">
                  <div className="col-md-4">
                     <img src={endbannr} loading="lazy" className="img-fluid mobilesection-hide rounded" />
                     <div className="card bg-transparent border-0 text-md-start text-center sections2">
                        <p className="h2 fw-bold mb-4">Explore, Select, and <span className="text-primary_two">Enroll in your dream college</span></p>
                       
                     </div>
                  </div>
                  <div className="col-lg-8 col-md-12">
                     <div className="row justify-content-center card-row-fld">
                        <div className="col-lg-5 col-md-5 col-12">
                           <div className="card shadow-sm bg-transparent border-0 adOn_cardHover rounded redCard my-2">
                              <div className="card-body">

                                 {/* < CiCircleList className="h1 mb-3 text-primary" /> */}
                              
                                 <FaBalanceScaleLeft className="h1 mb-3 text-primary" />
                                 <p className="h4 fw-bold text-primary">College Comparison   &amp; Tool</p>
                                 <p>Compare universities worldwide on factors like fees, rankings, facilities, and course offerings to make an informed decision.</p>
                              </div>
                           </div>
                        </div>
                        <div className="col-lg-5 col-md-5 col-12">
                           <div className="card shadow-sm bg-transparent border-0 adOn_cardHover rounded greenCard my-2">
                              <div className="card-body">

                              < FaClipboardList className="h1 mb-3 text-success" />
                                 <p className="h4 fw-bold text-success"> Career and Education counselling</p>
                                 <p>Align your academic choices with long-term career goals through personalized counseling.</p>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="row justify-content-center card-row-fld">
                        <div className="col-lg-5 col-md-5 col-12">
                           <div className="card shadow-sm bg-transparent border-0 adOn_cardHover rounded blueCard my-2">
                              <div className="card-body">

                                 <FaLock className="h1 mb-3 text-info" />

                                 <p className="h4 fw-bold text-primary">Tie-Ups with &amp; Top Universities Safe</p>
                                 <p>We collaborate with globally recognized, top-ranked universities &amp; offering world-class education and diverse program options.</p>
                              </div>
                           </div>
                        </div>
                        <div className="col-lg-5 col-md-5 col-12">
                           <div className="card shadow-sm bg-transparent border-0 adOn_cardHover rounded yellowCard my-2">
                              <div className="card-body">

                                 < FaComments className="h1 mb-3 text-warning" />
                                 <p className="h4 fw-bold text-warning"> Affordable Education Opportunities</p>
                                 <p>Our partnerships include universities with affordable fee structures, making quality education accessible for students from all backgrounds.</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <section className="topmbbs">
            <div className="container mt-3">
               {/* <h3 className="text-center" id="heads">Top MBBS Universities</h3> */}
               <h1 className="h2 fw-bold text-center">
                  Top MBBS
                  <span className="text-primary">&nbsp; Universities</span>
               </h1>
               <br></br>
               <p className="text-center" id="my-p">
                    Choosing the right college is a crucial decision that can significantly impact your future. With numerous colleges offering diverse courses and facilities, comparing them helps you identify the best fit for your academic and personal goals. Compare your top university choices using our free tool given below.
                    </p>             
               <div className="section sections3 ">
                  <div className="row">

                     <div className="col-md-4 mt-3">
                        <div className="card" id="icons-crd" style={{ width: '100%' }}>
                           <img src="https://media.nayeedisha.in/university_coverimage/Far_Eastern_Federal_University_cover_image.jpg" className="card-img-top" loading="lazy" alt="NayeeDisha" />
                           <div className="card-body iconerss-card">
                              <img src="https://media.nayeedisha.in/university_logos/Far_Eastern_Federal_University_logo.jpg" loading="lazy" alt="NayeeDisha" />
                              <span class="universitytype border-radius-5 text-white"> Private </span>
                              <a href="/university/far-eastern-federal-university">
                                 <h5 className="mt-1 text-transform">Far Eastern Federal University</h5>

                              </a>
                              <div>
                                 <div className='pt-2 college_part'>
                                    <p className='para1' style={{ color: '#000' }}>Fee-<span className='ugc' style={{ color: '#000' }}>  {data.total_budget ? data.total_budget : '25.5 Lakh Ruble'} </span></p>
                                    <p className='para1'>Estd. Year-<span className='ugc' style={{ color: '#000' }}> {data.country ? data.country : '1899'} </span></p>
                                 </div>

                                 <div className='college_part'>
                                                   <p className='para1'>Country <span className='ugc' style={{ color: '#000' }}> &nbsp;&nbsp;&nbsp;: {data.country ? data.country : 'Russia'} </span></p>
                                                      <p className='para1' style={{ color: '#000' }}> World Rank<span className='ugc' style={{ color: '#000' }}>  : {data.ranking ? data.ranking : '1441'} </span></p>
                                 </div>


                                 <div className='d-flex justify-content-between mt-3'>
                                    <Button type="button" className="btn btn-primary px-4 p-2 " data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo" id='btnr1'>
                                       Apply Now &nbsp;&nbsp;&nbsp;
                                       <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                                          <path fillRule="evenodd" clipRule="evenodd" d="M5.78009 4.45975C5.9209 4.6006 6 4.79161 6 4.99077C6 5.18993 5.9209 5.38094 5.78009 5.52179L1.53118 9.7707C1.46189 9.84244 1.37902 9.89966 1.28738 9.93902C1.19574 9.97839 1.09719 9.99911 0.997458 9.99997C0.897728 10.0008 0.798825 9.98183 0.706519 9.94407C0.614213 9.9063 0.530352 9.85053 0.45983 9.78001C0.389308 9.70949 0.333538 9.62563 0.295773 9.53332C0.258007 9.44102 0.239003 9.34211 0.23987 9.24238C0.240736 9.14266 0.261456 9.0441 0.30082 8.95246C0.340184 8.86083 0.397403 8.77795 0.46914 8.70866L4.18703 4.99077L0.469139 1.27288C0.332322 1.13122 0.256617 0.941496 0.258328 0.744563C0.260039 0.54763 0.33903 0.359248 0.478288 0.219989C0.617547 0.0807309 0.805929 0.001739 1.00286 2.80934e-05C1.1998 -0.00168282 1.38952 0.0740227 1.53118 0.210839L5.78009 4.45975Z" fill="white" />
                                       </svg>
                                    </Button>
                                    <a href="/university/far-eastern-federal-university">
                                       <Button className="btn" variant="outline-success">Read More</Button>
                                    </a>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>

                     <div className="col-md-4 mt-3">
                        <div className="card" id="icons-crd" style={{ width: '100%' }}>
                           <img src="https://media.nayeedisha.in/university_coverimage/Kazan_Federal_University_cover_image.jpg" loading="lazy" className="card-img-top" alt="NayeeDisha" />
                           <div className="card-body iconerss-card">
                              <img src="https://media.nayeedisha.in/university_logos/Kazan_Federal_University_logo.png" loading="lazy" alt="NayeeDisha" />
                              <span class="universitytype border-radius-5 text-white"> Private </span>
                              <a href="/university/kazan-state-medical-university">
                                 <h5 className="mt-1 text-transform">Kazan State Medical University</h5>
                              </a>
                              <div>
                                 <div className='pt-2 college_part'>
                                 <p className='para1' style={{ color: '#000' }}>Fee-<span className='ugc' style={{ color: '#000' }}>  {data.total_budget ? data.total_budget : '30 Lakh Ruble'} </span></p>
                                    <p className='para1' style={{ color: '#000' }}>Establish Year-<span className='ugc' style={{ color: '#000' }}>  {data.estb ? data.estb : '1804'} </span></p> 

                                 </div>

                                 <div className='college_part'>
                                 <p className='para1'>Country-<span className='ugc' style={{ color: '#000' }}> {data.country ? data.country : 'Russia'} </span></p>
                                                      <p className='para1' style={{ color: '#000' }}> World Rank<span className='ugc' style={{ color: '#000' }}>  : {data.ranking ? data.ranking : '6143'} </span></p>
                                 </div>
                                 <div className='d-flex justify-content-between mt-3'>
                                    <Button type="button" className="btn btn-primary px-4 p-2 " data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo" id='btnr1'>
                                       Apply Now &nbsp;&nbsp;&nbsp;
                                       <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                                          <path fillRule="evenodd" clipRule="evenodd" d="M5.78009 4.45975C5.9209 4.6006 6 4.79161 6 4.99077C6 5.18993 5.9209 5.38094 5.78009 5.52179L1.53118 9.7707C1.46189 9.84244 1.37902 9.89966 1.28738 9.93902C1.19574 9.97839 1.09719 9.99911 0.997458 9.99997C0.897728 10.0008 0.798825 9.98183 0.706519 9.94407C0.614213 9.9063 0.530352 9.85053 0.45983 9.78001C0.389308 9.70949 0.333538 9.62563 0.295773 9.53332C0.258007 9.44102 0.239003 9.34211 0.23987 9.24238C0.240736 9.14266 0.261456 9.0441 0.30082 8.95246C0.340184 8.86083 0.397403 8.77795 0.46914 8.70866L4.18703 4.99077L0.469139 1.27288C0.332322 1.13122 0.256617 0.941496 0.258328 0.744563C0.260039 0.54763 0.33903 0.359248 0.478288 0.219989C0.617547 0.0807309 0.805929 0.001739 1.00286 2.80934e-05C1.1998 -0.00168282 1.38952 0.0740227 1.53118 0.210839L5.78009 4.45975Z" fill="white" />
                                       </svg>
                                    </Button>
                                    <a href="/university/kazan-state-medical-university">
                                       <Button className="btn" variant="outline-success">Read More</Button>
                                    </a>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>

                     <div className="col-md-4 mt-3">
                        <div className="card" id="icons-crd" style={{ width: '100%' }}>
                           <img src="https://media.nayeedisha.in/university_coverimage/Pirogov_Russian_National_Research_Medical_University_cover_image.JPG" loading="lazy" className="card-img-top" alt="NayeeDisha" />
                           <div className="card-body iconerss-card">
                              <img src="https://media.nayeedisha.in/university_logos/Pirogov_Russian_National_Research_Medical_University_logo.jpg" loading="lazy" alt="NayeeDisha" />
                              <span class="universitytype border-radius-5 text-white"> Private </span>
                              <a href="university/pirogov-russian-national-research-medical-university">
                                 <h5 className="mt-1 text-transform">Pirogov Russian National Research Medical University</h5>
                              </a>
                              <div>
                                 <div className='pt-2 college_part'>
                                 <p className='para1' style={{ color: '#000' }}>Fee-<span className='ugc' style={{ color: '#000' }}>  {data.total_budget ? data.total_budget : '31 Lakh'} </span></p>
                                 
                                    <p className='para1' style={{ color: '#000' }}>Establish Year-<span className='ugc' style={{ color: '#000' }}>  {data.estb ? data.estb : '1906'} </span></p>
                                 </div>
                                 <div className='college_part'>
                                 <p className='para1'>Country-<span className='ugc' style={{ color: '#000' }}> {data.country ? data.country : 'Russia'} </span></p>
                                                      <p className='para1' style={{ color: '#000' }}> World Rank<span className='ugc' style={{ color: '#000' }}>  : {data.ranking ? data.ranking : '677'} </span></p>
                                 </div>
                                 <div className='d-flex justify-content-between mt-3'>
                                    <Button type="button" className="btn btn-primary px-4 p-2 " data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo" id='btnr1'>
                                       Apply Now &nbsp;&nbsp;&nbsp;
                                       <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                                          <path fillRule="evenodd" clipRule="evenodd" d="M5.78009 4.45975C5.9209 4.6006 6 4.79161 6 4.99077C6 5.18993 5.9209 5.38094 5.78009 5.52179L1.53118 9.7707C1.46189 9.84244 1.37902 9.89966 1.28738 9.93902C1.19574 9.97839 1.09719 9.99911 0.997458 9.99997C0.897728 10.0008 0.798825 9.98183 0.706519 9.94407C0.614213 9.9063 0.530352 9.85053 0.45983 9.78001C0.389308 9.70949 0.333538 9.62563 0.295773 9.53332C0.258007 9.44102 0.239003 9.34211 0.23987 9.24238C0.240736 9.14266 0.261456 9.0441 0.30082 8.95246C0.340184 8.86083 0.397403 8.77795 0.46914 8.70866L4.18703 4.99077L0.469139 1.27288C0.332322 1.13122 0.256617 0.941496 0.258328 0.744563C0.260039 0.54763 0.33903 0.359248 0.478288 0.219989C0.617547 0.0807309 0.805929 0.001739 1.00286 2.80934e-05C1.1998 -0.00168282 1.38952 0.0740227 1.53118 0.210839L5.78009 4.45975Z" fill="white" />
                                       </svg>
                                    </Button>
                                    <a href="university/pirogov-russian-national-research-medical-university">
                                       <Button className="btn" variant="outline-success">Read More</Button>
                                    </a>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>

                  </div>

                  <div className="row">

                     <div className="col-md-4 mt-3">
                        <div className="card" id="icons-crd" style={{ width: '100%' }}>
                           <img src="https://media.nayeedisha.in/university_coverimage/Bashkir_State_Medical_University__cover_image.jpg" loading="lazy" className="card-img-top" alt="NayeeDisha" />
                           <div className="card-body iconerss-card">
                              <img src="https://media.nayeedisha.in/university_logos/Bashkir_State_Medical_University__logo_BEklo2D.png" loading="lazy" alt="NayeeDisha" />
                              <span class="universitytype border-radius-5 text-white"> Private </span>
                              <a href="university/</div>bashkir-state-medical-university">
                                 <h5 className="mt-1 text-transform">Bashkir State Medical University</h5>
                              </a>
                              <div>
                                 <div className='pt-2 college_part'>
                                 <p className='para1' style={{ color: '#000' }}>Fee-<span className='ugc' style={{ color: '#000' }}>  {data.total_budget ? data.total_budget : '20 Lakh Ruble'} </span></p>
                                 
                                    <p className='para1' style={{ color: '#000' }}>Establish Year-<span className='ugc' style={{ color: '#000' }}>  {data.estb ? data.estb : '1932'} </span></p> 

                                 </div>
                                 <div className='college_part'>
                                 <p className='para1'>Country-<span className='ugc' style={{ color: '#000' }}> {data.country ? data.country : 'Russia'} </span></p>
                                                      <p className='para1' style={{ color: '#000' }}> World Rank<span className='ugc' style={{ color: '#000' }}>  : {data.ranking ? data.ranking : '5240'} </span></p>
                                 </div>
                                 <div className='d-flex justify-content-between mt-3'>
                                    <Button type="button" className="btn btn-primary px-4 p-2 " data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo" id='btnr1'>
                                       Apply Now &nbsp;&nbsp;&nbsp;
                                       <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                                          <path fillRule="evenodd" clipRule="evenodd" d="M5.78009 4.45975C5.9209 4.6006 6 4.79161 6 4.99077C6 5.18993 5.9209 5.38094 5.78009 5.52179L1.53118 9.7707C1.46189 9.84244 1.37902 9.89966 1.28738 9.93902C1.19574 9.97839 1.09719 9.99911 0.997458 9.99997C0.897728 10.0008 0.798825 9.98183 0.706519 9.94407C0.614213 9.9063 0.530352 9.85053 0.45983 9.78001C0.389308 9.70949 0.333538 9.62563 0.295773 9.53332C0.258007 9.44102 0.239003 9.34211 0.23987 9.24238C0.240736 9.14266 0.261456 9.0441 0.30082 8.95246C0.340184 8.86083 0.397403 8.77795 0.46914 8.70866L4.18703 4.99077L0.469139 1.27288C0.332322 1.13122 0.256617 0.941496 0.258328 0.744563C0.260039 0.54763 0.33903 0.359248 0.478288 0.219989C0.617547 0.0807309 0.805929 0.001739 1.00286 2.80934e-05C1.1998 -0.00168282 1.38952 0.0740227 1.53118 0.210839L5.78009 4.45975Z" fill="white" />
                                       </svg>
                                    </Button>
                                    <a href="university/</div>bashkir-state-medical-university">
                                       <Button className="btn" variant="outline-success">Read More</Button>
                                    </a>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>

                     <div className="col-md-4 mt-3">
                        <div className="card" id="icons-crd" style={{ width: '100%' }}>
                           <img src="https://media.nayeedisha.in/university_coverimage/Tver_State_Medical_University_cover_image.jpeg" loading="lazy"  className="card-img-top" alt="NayeeDisha" />
                           <div className="card-body iconerss-card">
                              <img src="https://media.nayeedisha.in/university_logos/Tver_State_Medical_University_logo.png" loading="lazy" alt="NayeeDisha" />
                              <span class="universitytype border-radius-5 text-white"> Private </span>
                              <a href="/university/tver-state-medical-university">
                                 <h5 className="mt-1 text-transform">Tver State Medical University</h5>
                              </a>
                              <div>
                                 <div className='pt-2 college_part'>
                                 <p className='para1' style={{ color: '#000' }}>Fee-<span className='ugc' style={{ color: '#000' }}>  {data.total_budget ? data.total_budget : '17.5 Lakh Ruble'} </span></p>
                                 
                                    <p className='para1' style={{ color: '#000' }}>Establish Year-<span className='ugc' style={{ color: '#000' }}>  {data.estb ? data.estb : '1936'} </span></p> 
                                </div>
                                 <div className='college_part'>
                                 <p className='para1'>Country-<span className='ugc' style={{ color: '#000' }}> {data.country ? data.country : 'Russia'} </span></p>
                                                      <p className='para1' style={{ color: '#000' }}> World Rank<span className='ugc' style={{ color: '#000' }}>  : {data.ranking ? data.ranking : '6765'} </span></p>
                                 </div>
                                 <div className='d-flex justify-content-between mt-3'>
                                    <Button type="button" className="btn btn-primary px-4 p-2 " data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo" id='btnr1'>
                                       Apply Now &nbsp;&nbsp;&nbsp;
                                       <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                                          <path fillRule="evenodd" clipRule="evenodd" d="M5.78009 4.45975C5.9209 4.6006 6 4.79161 6 4.99077C6 5.18993 5.9209 5.38094 5.78009 5.52179L1.53118 9.7707C1.46189 9.84244 1.37902 9.89966 1.28738 9.93902C1.19574 9.97839 1.09719 9.99911 0.997458 9.99997C0.897728 10.0008 0.798825 9.98183 0.706519 9.94407C0.614213 9.9063 0.530352 9.85053 0.45983 9.78001C0.389308 9.70949 0.333538 9.62563 0.295773 9.53332C0.258007 9.44102 0.239003 9.34211 0.23987 9.24238C0.240736 9.14266 0.261456 9.0441 0.30082 8.95246C0.340184 8.86083 0.397403 8.77795 0.46914 8.70866L4.18703 4.99077L0.469139 1.27288C0.332322 1.13122 0.256617 0.941496 0.258328 0.744563C0.260039 0.54763 0.33903 0.359248 0.478288 0.219989C0.617547 0.0807309 0.805929 0.001739 1.00286 2.80934e-05C1.1998 -0.00168282 1.38952 0.0740227 1.53118 0.210839L5.78009 4.45975Z" fill="white" />
                                       </svg>
                                    </Button>
                                    <a href="/university/tver-state-medical-university">
                                       <Button className="btn" variant="outline-success">Read More</Button>
                                    </a>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>

                   

                  </div>
                  {/* <div className="row">
                     <div className="col-md-4 mt-3">
                        <div className="card" id="icons-crd" style={{ width: '100%' }}>
                           <img src="https://media.nayeedisha.in/university_coverimage/Kathmandu_Medical_College_cover_image.jpg" className="card-img-top" alt="NayeeDisha" />
                           <div className="card-body iconerss-card">
                              <img src="https://media.nayeedisha.in/university_logos/Kathmandu_Medical_College_logo.png" alt="NayeeDisha" />
                              <span class="universitytype border-radius-5 text-white"> Private </span>

                              <a href="/university/kathmandu-medical-college">
                                 <h5 className="mt-1 text-transform">Kathmandu Medical College</h5>
                              </a>
                              <div>
                                 <div className='pt-2 college_part'>
                                    <p className='para1' style={{ color: '#000' }}>Establish Year-<span className='ugc' style={{ color: '#000' }}>  {data.estb ? data.estb : '1997'} </span></p> |
                                    <p className='para1'>Country-<span className='ugc' style={{ color: '#000' }}> {data.country ? data.country : 'Russia'} </span></p>
                                 </div>
                                 <div className='d-flex justify-content-between mt-3'>
                                    <Button type="button" className="btn btn-primary px-4 p-2 " data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo" id='btnr1'>
                                       Apply Now &nbsp;&nbsp;&nbsp;
                                       <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                                          <path fillRule="evenodd" clipRule="evenodd" d="M5.78009 4.45975C5.9209 4.6006 6 4.79161 6 4.99077C6 5.18993 5.9209 5.38094 5.78009 5.52179L1.53118 9.7707C1.46189 9.84244 1.37902 9.89966 1.28738 9.93902C1.19574 9.97839 1.09719 9.99911 0.997458 9.99997C0.897728 10.0008 0.798825 9.98183 0.706519 9.94407C0.614213 9.9063 0.530352 9.85053 0.45983 9.78001C0.389308 9.70949 0.333538 9.62563 0.295773 9.53332C0.258007 9.44102 0.239003 9.34211 0.23987 9.24238C0.240736 9.14266 0.261456 9.0441 0.30082 8.95246C0.340184 8.86083 0.397403 8.77795 0.46914 8.70866L4.18703 4.99077L0.469139 1.27288C0.332322 1.13122 0.256617 0.941496 0.258328 0.744563C0.260039 0.54763 0.33903 0.359248 0.478288 0.219989C0.617547 0.0807309 0.805929 0.001739 1.00286 2.80934e-05C1.1998 -0.00168282 1.38952 0.0740227 1.53118 0.210839L5.78009 4.45975Z" fill="white" />
                                       </svg>
                                    </Button>
                                    <a href="/university/kathmandu-medical-college">
                                       <Button className="btn" variant="outline-success">Read More</Button>
                                    </a>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-4 mt-3">
                        <div className="card" id="icons-crd" style={{ width: '100%' }}>
                           <img src="https://media.nayeedisha.in/university_coverimage/Nepal_Medical_College_cover_image.png" className="card-img-top" alt="NayeeDisha" />
                           <div className="card-body iconerss-card">
                              <img src="https://media.nayeedisha.in/university_logos/Nepal_Medical_College_logo.png" alt="NayeeDisha" />
                              <span class="universitytype border-radius-5 text-white"> Private </span>
                              <a href="/university/nepal-medical-college">
                                 <h5 className="mt-1 text-transform">Nepal Medical College</h5>
                              </a>
                              <div>
                                 <div className='pt-2 college_part'>
                                    <p className='para1' style={{ color: '#000' }}>Establish Year-<span className='ugc' style={{ color: '#000' }}>  {data.estb ? data.estb : '1997'} </span></p> |
                                    <p className='para1'>Country-<span className='ugc' style={{ color: '#000' }}> {data.country ? data.country : 'Russia'} </span></p>
                                 </div>
                                 <div className='d-flex justify-content-between mt-3'>
                                    <Button type="button" className="btn btn-primary px-4 p-2 " data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo" id='btnr1'>
                                       Apply Now &nbsp;&nbsp;&nbsp;
                                       <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                                          <path fillRule="evenodd" clipRule="evenodd" d="M5.78009 4.45975C5.9209 4.6006 6 4.79161 6 4.99077C6 5.18993 5.9209 5.38094 5.78009 5.52179L1.53118 9.7707C1.46189 9.84244 1.37902 9.89966 1.28738 9.93902C1.19574 9.97839 1.09719 9.99911 0.997458 9.99997C0.897728 10.0008 0.798825 9.98183 0.706519 9.94407C0.614213 9.9063 0.530352 9.85053 0.45983 9.78001C0.389308 9.70949 0.333538 9.62563 0.295773 9.53332C0.258007 9.44102 0.239003 9.34211 0.23987 9.24238C0.240736 9.14266 0.261456 9.0441 0.30082 8.95246C0.340184 8.86083 0.397403 8.77795 0.46914 8.70866L4.18703 4.99077L0.469139 1.27288C0.332322 1.13122 0.256617 0.941496 0.258328 0.744563C0.260039 0.54763 0.33903 0.359248 0.478288 0.219989C0.617547 0.0807309 0.805929 0.001739 1.00286 2.80934e-05C1.1998 -0.00168282 1.38952 0.0740227 1.53118 0.210839L5.78009 4.45975Z" fill="white" />
                                       </svg>
                                    </Button>
                                    <a href="/university/nepal-medical-college">
                                       <Button className="btn" variant="outline-success">Read More</Button>
                                    </a>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-4 mt-3">
                        <div className="card" id="icons-crd" style={{ width: '100%' }}>
                           <img src="https://media.nayeedisha.in/university_coverimage/KIST_Medical_College_cover_image.jpg" className="card-img-top" alt="NayeeDisha" />
                           <div className="card-body iconerss-card">
                              <img src="https://media.nayeedisha.in/university_logos/KIST_Medical_College_logo.png" alt="NayeeDisha" />
                              <span class="universitytype border-radius-5 text-white"> Private </span>
                              <a href="/university/kist-medical-college">
                                 <h5 className="mt-1 text-transform">KIST Medical College</h5>
                              </a>
                              <div>
                                 <div className='pt-2 college_part'>
                                    <p className='para1' style={{ color: '#000' }}>Establish Year-<span className='ugc' style={{ color: '#000' }}>  {data.estb ? data.estb : '2006'} </span></p> |
                                    <p className='para1'>Country-<span className='ugc' style={{ color: '#000' }}> {data.country ? data.country : 'Russia'} </span></p>
                                 </div>
                                 <div className='d-flex justify-content-between mt-3'>
                                    <Button type="button" className="btn btn-primary px-4 p-2 " data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo" id='btnr1'>
                                       Apply Now &nbsp;&nbsp;&nbsp;
                                       <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                                          <path fillRule="evenodd" clipRule="evenodd" d="M5.78009 4.45975C5.9209 4.6006 6 4.79161 6 4.99077C6 5.18993 5.9209 5.38094 5.78009 5.52179L1.53118 9.7707C1.46189 9.84244 1.37902 9.89966 1.28738 9.93902C1.19574 9.97839 1.09719 9.99911 0.997458 9.99997C0.897728 10.0008 0.798825 9.98183 0.706519 9.94407C0.614213 9.9063 0.530352 9.85053 0.45983 9.78001C0.389308 9.70949 0.333538 9.62563 0.295773 9.53332C0.258007 9.44102 0.239003 9.34211 0.23987 9.24238C0.240736 9.14266 0.261456 9.0441 0.30082 8.95246C0.340184 8.86083 0.397403 8.77795 0.46914 8.70866L4.18703 4.99077L0.469139 1.27288C0.332322 1.13122 0.256617 0.941496 0.258328 0.744563C0.260039 0.54763 0.33903 0.359248 0.478288 0.219989C0.617547 0.0807309 0.805929 0.001739 1.00286 2.80934e-05C1.1998 -0.00168282 1.38952 0.0740227 1.53118 0.210839L5.78009 4.45975Z" fill="white" />
                                       </svg>
                                    </Button>
                                    <a href="/university/kist-medical-college">
                                       <Button className="btn" variant="outline-success">Read More</Button>
                                    </a>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>

                  </div> */}
              
               </div>

               <div className="container mobilesection-hide">
            <div className="row ">
               <div className="my-5">


                  {isLoading ? (
                     <div className="row">
                        {skeletonArray.map((_, index) => (
                           <div className="col-md-3" key={index}>
                              <Skeleton height={200} />
                              <div className="mt-2">
                                 <Skeleton height={40} width="80%" />
                              </div>
                              <div className="mt-2">
                                 <Skeleton height={20} width="60%" />
                              </div>
                              <div className="mt-2">
                                 <Skeleton height={30} width="40%" />
                              </div>
                           </div>
                        ))}
                     </div>
                  ) : (

                     <div className='row position-stickey'>
                        <div className="custom-carousel">
                           <button className="custom-prev-btn" onClick={handlePrev}>
                              <GrFormPreviousLink className="icons-top" />
                           </button>
                           <OwlCarousel
                              className="owl-theme"
                              {...options}
                              ref={el => (carouselRef = el)}
                           >
                              {universities.map((data) => {
                                 return (
                                    <Link to={`/university/${data.slug}`} key={data.slug}>
                                       <div className="items">
                                          <div className="card" id="icons-crd" style={{ width: '100%' }}>
                                             <img src={data.cover_image} className="card-img-top" loading="lazy" alt="NayeeDisha" />
                                             <span class="universitytype border-radius-5 text-white"> Private </span>
                                             <div className="card-body iconerss-card">
                                                <img src={data.logo} loading="lazy" alt="NayeeDisha" />
                                                <Link to={`/university/${data.slug}`}>
                                                   <h5 className="mt-1 text-transform">{data.name}</h5>
                                                </Link>
                                                <hr/>
                                                <div>
                                                   <div className='pt-2 college_part'>
                                                      <p className='para1' style={{ color: '#000' }}>Fee <span className='ugc' style={{ color: '#000' }}>: {data.total_budget.total_budget ? data.total_budget.total_budget : 'Not found'} </span></p>
                                                      <p className='para1'>Estd. Year <span className='ugc' style={{ color: '#000' }}>&nbsp;&nbsp;&nbsp;: {data.estb ? data.estb : 'Not found'} </span></p>
                                                   </div>
                     
                                                </div>
                                                <div>
                                                   <div className='college_part'>
                                                   <p className='para1'>Country <span className='ugc' style={{ color: '#000' }}> &nbsp;&nbsp;&nbsp;: {data.country ? data.country : 'Not found'} </span></p>
                                                      <p className='para1' style={{ color: '#000' }}> World Rank<span className='ugc' style={{ color: '#000' }}>  : {data.ranking.ranking ? data.ranking.ranking : 'Not found'} </span></p>
                                                   </div>
                                                   <div className='d-flex justify-content-between mt-3'>
                                                      <Button type="button" className="btn btn-primary px-4 p-2 " data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo" id='btnr1'>
                                                         Apply Now &nbsp;&nbsp;&nbsp;
                                                         <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M5.78009 4.45975C5.9209 4.6006 6 4.79161 6 4.99077C6 5.18993 5.9209 5.38094 5.78009 5.52179L1.53118 9.7707C1.46189 9.84244 1.37902 9.89966 1.28738 9.93902C1.19574 9.97839 1.09719 9.99911 0.997458 9.99997C0.897728 10.0008 0.798825 9.98183 0.706519 9.94407C0.614213 9.9063 0.530352 9.85053 0.45983 9.78001C0.389308 9.70949 0.333538 9.62563 0.295773 9.53332C0.258007 9.44102 0.239003 9.34211 0.23987 9.24238C0.240736 9.14266 0.261456 9.0441 0.30082 8.95246C0.340184 8.86083 0.397403 8.77795 0.46914 8.70866L4.18703 4.99077L0.469139 1.27288C0.332322 1.13122 0.256617 0.941496 0.258328 0.744563C0.260039 0.54763 0.33903 0.359248 0.478288 0.219989C0.617547 0.0807309 0.805929 0.001739 1.00286 2.80934e-05C1.1998 -0.00168282 1.38952 0.0740227 1.53118 0.210839L5.78009 4.45975Z" fill="white" />
                                                         </svg>
                                                      </Button>
                                                      <a href={`/university/${data.slug}`}>
                                                         <Button className="btn" variant="outline-success">Read More</Button>
                                                      </a>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </Link>
                                 );
                              })}
                           </OwlCarousel>
                           <button className="custom-next-btn" onClick={handleNext}>
                              <GrFormNextLink className="icons-top" />
                           </button>
                        </div>
                    

                     </div>

                  )}

               </div>
            </div>
         </div>
<br></br>
               <a href="/study-abroad"> <div className="row justify-content-center">
                  <div className="col-lg-3 col-md-6 col-12 text-center">
                     <button className="btn btn-outline-primary" >
                        View All Univesities &nbsp; <IoIosArrowRoundForward />
                     </button>
                  </div>
               </div></a>

               {/* <div className="text-center mt-3">

                  <a href="/study-abroad" className="text-white"><button className="btn btn-warning text-white mt-3
                  " id="btnrs1">  View All Universities &nbsp; <IoIosArrowRoundForward /></button></a>
               </div> */}
            </div>
         </section> 
         <section className="py-70 d-none">
            <div className="container">
               <div className="row">
                  <div className="col-12 text-center">
                     <p className="h2 fw-bold text-start">Expert Education Counselors at Your Service: <span className="text-primary">Unlock Your Potential of Success</span></p>
                     <p className="">Our dedicated education counselors provide personalized guidance to help you make informed decisions about your academic and career paths. From choosing the right program to navigating admission processes, we are here to support you at every step of your journey toward success. </p>
                     
                  </div>
               </div>
               <Slider {...settings}>
                  <div>
                     <div className="card position-relative shadow-sm overflow-visible rounded">
                        <div className="adOn_CounsellingCounter fa-fade">
                           <p className="badge mb-0">500+ Counsellings</p>
                        </div>


                        <img className="rounded img-fluid w-100" alt="Gautam" loading="lazy" srcset={Counselling} src={Counselling} width="400" height="533" />
                        <div className="card-body counsellorDetailsCard bg-body">
                           <p className="fw-bold text-primary mb-1">Vanshika </p>
                           <p className="fw-semibold mb-0 fs-13 mb-0">Counsellor</p>
                           <p className="fs-13 mb-0">2 Years+ of Experience</p>
                           <div className="d-flex align-items-center justify-content-between">
                              <ul className="list-inline m-0 text-warning">
                                 < FaStar />
                                 < FaStar />
                                 < FaStar />
                                 < FaStar />

                                 < FaRegStarHalfStroke />

                              </ul>
                              <button className="btn-link fs-12 btn p-0" tabindex="-1">Get Counselling</button>

                           </div>

                        </div>

                     </div>
                    
                  </div>
                  <div>
                     <div className="card position-relative shadow-sm overflow-visible rounded">
                        <div className="adOn_CounsellingCounter fa-fade">
                           <p className="badge mb-0">500+ Counsellings</p>
                        </div>


                        <img className="rounded img-fluid w-100" alt="Gautam" loading="lazy" srcset={Counselling} src={Counselling} width="400" height="533" />
                      <div className="card-body counsellorDetailsCard bg-body">
                           <p className="fw-bold text-primary mb-1">Bhumika Gautam</p>
                           <p className="fw-semibold mb-0 fs-13 mb-0">Counsellor</p>
                           <p className="fs-13 mb-0">5 Years of Experience</p>
                           <div className="d-flex align-items-center justify-content-between">
                              <ul className="list-inline m-0 text-warning">
                                 < FaStar />
                                 < FaStar />
                                 < FaStar />
                                 < FaStar />

                                 < FaRegStarHalfStroke />

                              </ul>
                              <button className="btn-link fs-12 btn p-0" tabindex="-1">Get Counselling</button>

                           </div>

                        </div>

                     </div>
                    

                  </div>
                  <div>
                     <div className="card position-relative shadow-sm overflow-visible rounded">
                        <div className="adOn_CounsellingCounter fa-fade">
                           <p className="badge mb-0">500+ Counsellings</p>
                        </div>


                        <img className="rounded img-fluid w-100" alt="Gautam" loading="lazy" srcset={Counselling} src={Counselling} width="400" height="533" />
                     <div className="card-body counsellorDetailsCard bg-body">
                           <p className="fw-bold text-primary mb-1">Bhumika Gautam</p>
                           <p className="fw-semibold mb-0 fs-13 mb-0">Counsellor</p>
                           <p className="fs-13 mb-0">5 Years of Experience</p>
                           <div className="d-flex align-items-center justify-content-between">
                              <ul className="list-inline m-0 text-warning">
                                 < FaStar />
                                 < FaStar />
                                 < FaStar />
                                 < FaStar />

                                 < FaRegStarHalfStroke />

                              </ul>
                              <button className="btn-link fs-12 btn p-0" tabindex="-1">Get Counselling</button>

                           </div>

                        </div>

                     </div>
                    

                  </div>
                  <div>
                     <div className="card position-relative shadow-sm overflow-visible rounded">
                        <div className="adOn_CounsellingCounter fa-fade">
                           <p className="badge mb-0">500+ Counsellings</p>
                        </div>


                        <img className="rounded img-fluid w-100" alt="Bhumika Gautam" loading="lazy" srcset={Counselling} src={Counselling} width="400" height="533" />
                        <div className="card-body counsellorDetailsCard bg-body">
                           <p className="fw-bold text-primary mb-1">Bhumika Gautam</p>
                           <p className="fw-semibold mb-0 fs-13 mb-0">Counsellor</p>
                           <p className="fs-13 mb-0">5 Years of Experience</p>
                           <div className="d-flex align-items-center justify-content-between">
                              <ul className="list-inline m-0 text-warning">
                                 < FaStar />
                                 < FaStar />
                                 < FaStar />
                                 < FaStar />

                                 < FaRegStarHalfStroke />

                              </ul>
                              <button className="btn-link fs-12 btn p-0" tabindex="-1">Get Counselling</button>

                           </div>

                        </div>

                     </div>
                    

                  </div>
                  <div>
                     <div className="card position-relative shadow-sm overflow-visible rounded">
                        <div className="adOn_CounsellingCounter fa-fade">
                           <p className="badge mb-0">500+ Counsellings</p>
                        </div>


                        <img className="rounded img-fluid w-100" alt="Gautam" loading="lazy" srcset={Counselling} src={Counselling} width="400" height="533" />
                        <div className="card-body counsellorDetailsCard bg-body">
                           <p className="fw-bold text-primary mb-1">Bhumika Gautam</p>
                           <p className="fw-semibold mb-0 fs-13 mb-0">Counsellor</p>
                           <p className="fs-13 mb-0">5 Years of Experience</p>
                           <div className="d-flex align-items-center justify-content-between">
                              <ul className="list-inline m-0 text-warning">
                                 < FaStar />
                                 < FaStar />
                                 < FaStar />
                                 < FaStar />

                                 < FaRegStarHalfStroke />

                              </ul>
                              <button className="btn-link fs-12 btn p-0" tabindex="-1">Get Counselling</button>

                           </div>

                        </div>

                     </div>
                    

                  </div>
               </Slider>


            </div>
         </section>


         <section className="py-70 admissionprocess">
            <div className="container-fluid">
               <div className="row">
                  <div className="col-md-12">
                     <p className="h2 fw-bold text-white text-center pb-4">Your Pathway To MBBS Abroad: <span className="text-warning">Simplifying Admission Process</span></p>
                  </div>
               </div>
               <div className="row">
                  <div className="col-lg-3 col-md-6 mt-3">
                  <div className="flip-card-container my-2">
                            <div className="flip-car">
                                <div className="flip-card-front rounded admissionprocess_card_text cardone text-center">
                                <FaUniversity className="fafabook" />
                                {/* <GiSpellBook className="fafabook" /> */}
                                    <i className="fa-solid fa-book-open-reader  h2 mb-3"></i>
                                    <p className="h5 fw-bold mt-2">University Selection</p>
                                    <hr className="w-50 offset-3" />
                                    <p>Our experts will help you shortlist the best medical universities.</p>
                                </div>
                              
                            </div>
                        </div>
                  </div>

                  <div className="col-lg-3 col-md-6 mt-3">
                  <div className="flip-card-container my-2">
                            <div className="flip-car">
                                <div className="flip-card-front cardtwo rounded lavendar text-center">
                                
                                <FaWpforms className="fafabook" />
                                    <i className="fa-solid fa-book-open-reader  h2 mb-3"></i>
                                    <p className="h5 fw-bold mt-2">Application Process</p>
                                    <hr className="w-50 offset-3" />
                                    <p>Submission of academic documents & application form. We handle the entire process to ensure your application is accepted.</p>
                                </div>
                             
                            </div>
                        </div>
                  </div>

                  <div className="col-lg-3 col-md-6 mt-3">
                  <div className="flip-card-container my-2">
                            <div className="admissionprocess_card">
                                <div className="flip-card-front rounded cardthree card_visa text-center">
                                
                                <GiPassport className="fafabook" />
                                    <i className="fa-solid fa-book-open-reader  h2 mb-3"></i>
                                    <p className="h5 fw-bold mt-2">Visa and Admission</p>
                                    <hr className="w-50 offset-3" />
                                    <p>After receiving your admission letter, we assist with your visa application and other formalities to secure your student visa.</p>
                                </div>
                                
                            </div>
                        </div>
                  </div>

                  <div className="col-lg-3 col-md-6 mt-3">
                  <div className="flip-card-container my-2">
                            <div className="flip-car">
                                <div className="flip-card-front cardfour rounded card_travel text-center">
                                {/* <img src={Counsellin} className="fafabook" /> */}
                                <TiPlaneOutline className="fafabook" />
                                
                                    <i className="fa-solid fa-book-open-reader  h2 mb-3"></i>
                                    <p className="h5 fw-bold">Travel and Accommodation Setup</p>
                                    <hr className="w-50 offset-3" />
                                    <p>We guide you through pre-departure arrangements for a smooth transition to your new university.</p>
                                </div>
                                
                            </div>
                        </div>
                  </div>
               </div>
            </div>
         </section>

         {/* <section className="py-70 bg-light">
    <div className="container">
        <div className="row align-items-center justify-content-between">
            <div className="col-md-4">
                <div className="card bg-transparent border-0 text-md-start text-center">
                    <p className="h2 fw-bold mb-4">Explore, Select, and <span className="text-primary">Enroll in your dream college</span></p>
                    <a href="/about" className="btn-link">Dig Deeper <i className="fa fa-arrow-right ms-3"></i></a>
                </div>
            </div>
            <div className="col-md-8">
                <div className="row justify-content-center card-row-fld">
                    <div className="col-md-5 col-6">
                        <div className="card shadow-sm bg-transparent border-0 adOn_cardHover rounded redCard my-2">
                            <div className="card-body">
                                <i className="fas fa-list h1 mb-3 text-primary"></i>
                                <p className="h4 fw-bold text-primary">Extensive College &amp; Course Selection</p>
                                <p>From Universities to Courses, we have got your Education covered.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5 col-6">
                        <div className="card shadow-sm bg-transparent border-0 adOn_cardHover rounded greenCard my-2">
                            <div className="card-body">
                                <i className="fas fa-scale-unbalanced h1 mb-3 text-success"></i>
                                <p className="h4 fw-bold text-success"> Compare Universities</p>
                                <p>Unleash the power of AI assistance to effortlessly compare Universities &amp; find your match.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center card-row-fld">
                    <div className="col-md-5 col-6">
                        <div className="card shadow-sm bg-transparent border-0 adOn_cardHover rounded blueCard my-2">
                            <div className="card-body">
                                <i className="fas fa-lock h1 mb-3 text-info"></i>
                                <p className="h4 fw-bold text-primary"> Safe &amp; Secure</p>
                                <p>Input your credentials &amp; let us guide you with your aspirations safely.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5 col-6">
                        <div className="card shadow-sm bg-transparent border-0 adOn_cardHover rounded yellowCard my-2">
                            <div className="card-body">
                                <i className="fas fa-comments h1 mb-3 text-warning"></i>
                                <p className="h4 fw-bold text-warning"> Unlock Wisdom with expert's advice</p>
                                <p>Our experts will assist you with their tailored advice to navigate the road to excellence</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> */}
         <br></br>
         {/* <div className="container">
            <div className="row ">
               <div className="my-5">
                  {isLoading ? (
                     <div className="row">
                        {skeletonArray.map((_, index) => (
                           <div className="col-md-3" key={index}>
                              <Skeleton height={200} />
                              <div className="mt-2">
                                 <Skeleton height={40} width="80%" />
                              </div>
                              <div className="mt-2">
                                 <Skeleton height={20} width="60%" />
                              </div>
                              <div className="mt-2">
                                 <Skeleton height={30} width="40%" />
                              </div>
                           </div>
                        ))}
                     </div>
                  ) : (

                     <div className='row position-stickey'>
                        <div className="custom-carousel">
                           <button className="custom-prev-btn" onClick={handlePrev}>
                              <GrFormPreviousLink className="icons-top" />
                           </button>
                           <OwlCarousel
                              className="owl-theme"
                              {...options}
                              ref={el => (carouselRef = el)}
                           >
                              {universities.map((data) => {
                                 return (
                                    <Link to={`/university/${data.slug}`} key={data.slug}>
                                       <div className="items">
                                          <div className="card" id="icons-crd" style={{ width: '100%' }}>
                                             <img src={data.cover_image} className="card-img-top" alt="NayeeDisha" />
                                             <div className="card-body iconerss-card">
                                                <img src={data.logo} alt="NayeeDisha" />
                                                <Link to={`/university/${data.slug}`}>
                                                   <h5 className="mt-1 text-transform">{data.name}</h5>
                                                </Link>
                                                <div>
                                                   <div className='pt-2 college_part'>
                                                      <p className='para1' style={{ color: '#000' }}>Establish Year-<span className='ugc' style={{ color: '#000' }}>  {data.estb ? data.estb : 'Not found'} </span></p> |
                                                      <p className='para1'>Country-<span className='ugc' style={{ color: '#000' }}> {data.country ? data.country : 'Not found'} </span></p>
                                                   </div>
                                                   <div className='d-flex justify-content-between mt-3'>
                                                      <Button type="button" className="btn btn-primary px-4 p-2 " data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo" id='btnr1'>
                                                         Apply Now &nbsp;&nbsp;&nbsp;
                                                         <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M5.78009 4.45975C5.9209 4.6006 6 4.79161 6 4.99077C6 5.18993 5.9209 5.38094 5.78009 5.52179L1.53118 9.7707C1.46189 9.84244 1.37902 9.89966 1.28738 9.93902C1.19574 9.97839 1.09719 9.99911 0.997458 9.99997C0.897728 10.0008 0.798825 9.98183 0.706519 9.94407C0.614213 9.9063 0.530352 9.85053 0.45983 9.78001C0.389308 9.70949 0.333538 9.62563 0.295773 9.53332C0.258007 9.44102 0.239003 9.34211 0.23987 9.24238C0.240736 9.14266 0.261456 9.0441 0.30082 8.95246C0.340184 8.86083 0.397403 8.77795 0.46914 8.70866L4.18703 4.99077L0.469139 1.27288C0.332322 1.13122 0.256617 0.941496 0.258328 0.744563C0.260039 0.54763 0.33903 0.359248 0.478288 0.219989C0.617547 0.0807309 0.805929 0.001739 1.00286 2.80934e-05C1.1998 -0.00168282 1.38952 0.0740227 1.53118 0.210839L5.78009 4.45975Z" fill="white" />
                                                         </svg>
                                                      </Button>
                                                      <a href={`/university/${data.slug}`}>
                                                         <Button className="btn" variant="outline-success">Read More</Button>
                                                      </a>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </Link>
                                 );
                              })}
                           </OwlCarousel>
                           <button className="custom-next-btn" onClick={handleNext}>
                              <GrFormNextLink className="icons-top" />
                           </button>
                        </div>
                        <div className="text-center">

                           <a href="/study-abroad" className="text-white"><button className="btn btn-warning text-white mt-3 py-3 " id="btnrs1">  View All Universities </button></a>
                        </div>

                     </div>

                  )}

               </div>
            </div>
         </div> */}
         {/* for mobile */}
         <CompareCollege />
         {/* <section className="compare_colleges mt-2 mb-2">
            <div className="container mt-4">
                <div className="text-center mt-4">
                    <h3></h3>
                    <h3 className="text-center" id="heads">
                        <span id="explore">Compare </span> Colleges
                    </h3>
                    <p className="text-center" id="my-p">
                        Explore your preferred streams to learn about the relevant colleges, exams, and more!
                    </p>
                </div>
                <div className="row mt-4">
                    {selectedCollege.map((college, index) => (
                        <div className="col-md-4 mt-2" key={index}>
                            <div className="compare ">
                                {college ? (
                                    <div className="card" id="icons-crd" style={{ width: '100%' }}>
                                         <button className="compare_closebutton" onClick={() => handleRemoveCollege(index)}>
                                            <FaTimes />
                                        </button>
                                        <div
                                            className="university-section "
                                            style={{
                                                width: '100%',
                                                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${college.cover_image})`,
                                                backgroundRepeat: 'no-repeat',
                                                backgroundSize: '100% 186%',
                                                height: '100px',
                                                backgroundPosition: 'center'
                                            }}
                                        >

                                        </div>
                                        <div className="card-body comparetext">
                                            <img src={college.logo} className="" alt="NayeeDisha" />
                                            
                                                <h5 className="mt-2 f-16 text-transform">{college.name}</h5>
                                           
                                            <div>
                                                <div className="pt-1">
                                               
                                                    <p className="para1" style={{ color: '#000' }}>
                                                        Country -
                                                        <span className="ugc" style={{ color: '#000' }}>
                                                            {college.country ? college.country : 'Not found'}
                                                        </span>
                                                    </p>
                                                </div>
                                               
                                            </div>
                                        </div>
                                      
                                    </div>
                                ) : (
                                    <>
                                    <div className="text-center">

                                    
                                        <GoPlusCircle className="plusicon" onClick={() => handleAddCollegeClick(index)} />
                                        <p onClick={() => handleAddCollegeClick(index)}>Add College</p>
                                        {showDropdown[index] && (
                                            <div className="custom-dropdown">
                                                <input
                                                    type="text"
                                                    value={searchQuery[index]}
                                                    onChange={(e) => handleSearchChange(index, e.target.value)}
                                                    placeholder="Search college by name"
                                                    className="dropdown-input input_compair"
                                                    disabled={index > 0 && !selectedCollege[index - 1]}
                                                />
                                                <div className="dropdown-list compare_dropdown">
                                                    {filteredColleges(index).map(college => (
                                                        <div
                                                            key={college.uid}
                                                            className="dropdown-item compare_itemlist"
                                                            onClick={() => handleCollegeChange(index, college)}
                                                        >
                                                            {college.name}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
                <br />
                <div className="text-center mt-2">
                    <button
                        type="button"
                        className="btn btn-primary mt-3"
                        disabled={!selectedCollege[0]}
                        onClick={handleCompareNow}
                    >
                        Compare Now <IoGitCompareOutline />
                    </button>
                </div>
           

 </div>
        </section> */}

         {/* end mobile */}
         {/*  */}
         {/* copy-section-another */}






         {/* <!-- SECTION4--> */}

         {/* END SECTION4 */}




         {/* SECTION 7 */}



         <section className="py-70 bg-light pt-5">
            <div className="container">

           
            <h2 className="h2 fw-bold my-3 text-center">
               Your Dream Education Starts Here:

               <span className="text-primary">&nbsp;Scholarship Hunt 2024-2025!</span>
            </h2>
            <p className="text-center mt-3" id="my-p">
            Nayee Disha offers a unique scholarship exam for students in grades 9 to 12, designed to reward academic talent and ambition. Excelling in this exam can secure financial assistance to help you pursue your educational goals.
                  </p>
           
            </div>
            
            <picture>
                  {/* Mobile Image */}
                  <source media="(max-width: 576px)" srcSet={hunexammobile} />
                  {/* Tablet Image */}
                  <source media="(max-width: 992px)" srcSet={hunexammobile} />
                  {/* Desktop Image */}
                  <img
                     className="d-block w-100"
                     src={huntexam} // Fallback image for larger screens
                     alt="Responsive slide"
                     loading="lazy"
                  />
               </picture>
          
         </section>

         {/* END SECTION7 */}
         {/* <!-- SECTION5 --> */}
         {newsdetails.title ?
            <div className="section5">
               <div className="container pt-5 pb-5">
                  {isLoading ? (
                     <div className='text-center'>
                        <MagnifyingGlass
                           visible={true}
                           height="80"
                           width="80"
                           ariaLabel="magnifying-glass-loading"
                           wrapperStyle={{}}
                           wrapperClass="magnifying-glass-wrapper"
                           glassColor="#c0efff"
                           color="#e15b64"
                        />
                     </div>
                  ) : (
                     <div className="row">
                        <h3 className="text-center"><span className="latest">Latest</span> News & Stories</h3>
                        <p className="text-center">Explore your preferred streams to learn about the relevant colleges, exams and more!</p>
                        {newsdetails.slice(0, 4).map((newsdetails) => {
                           const truncatedContent = newsdetails.content?.split(' ').slice(0, 25).join(' ');
                           // Convert created_at to a Date object
                           const createdAtDate = new Date(newsdetails.created_at);

                           // Define months array
                           const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

                           // Get day, month, and year
                           const day = createdAtDate.getDate();
                           const monthIndex = createdAtDate.getMonth();
                           const year = createdAtDate.getFullYear();

                           // Format the date string
                           const formattedDate = `${day} ${months[monthIndex]} ${year}`;
                           return (
                              <div className="col-md-6  mt-3">
                                 <div className="card" style={{ width: '100%' }}>
                                    <div className="card-body crdr1 ">
                                       <div className="row">
                                          <div className="col-md-12 col-lg-8 col-6 ">
                                             <h5 style={{ textTransform: 'capitalize' }}>{newsdetails.title ? newsdetails.title : 'Not found'}</h5>
                                             <p>{formattedDate}</p>

                                             <p className="next-para">  <div dangerouslySetInnerHTML={{ __html: truncatedContent || '' }} />

                                                <Link to={`/news-details/${newsdetails.slug}`}>  View More </Link></p>
                                          </div>
                                          <div className="col-md-12 col-lg-4 col-6">
                                             <div> <img src={newsdetails.thumbnail} alt={newsdetails.title} loading="lazy" className="news_image" /></div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>

                              </div>
                           )
                        })
                        }
                     </div>
                  )}

                  <div className="text-center mt-3">
                     <a href="/news"><button type="button" class="btn btn-warning viewallbutton wave_talk text-white">View All</button></a>
                  </div>
               </div>

            </div>
            : null
         }

         {/* next slider */}

         <div className="section5 mt-4">
            <h3 className="text-center headingline">
               <span className="latest blog">Latest </span> Blogs
            </h3>

            <div className="container pt-5 pb-5">
               {isLoading ? (
                  <div className='text-center'>
                     <MagnifyingGlass
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="magnifying-glass-loading"
                        wrapperStyle={{}}
                        wrapperClass="magnifying-glass-wrapper"
                        glassColor="#c0efff"
                        color="#e15b64"
                     />
                  </div>
               ) : (
                  <div className="row">
                     {blogdetails.slice(0, 3).map((blogdetails) => {
                        const truncatedContent = blogdetails.content?.split(' ').slice(0, 25).join(' ');
                        // Convert created_at to a Date object
                        const createdAtDate = new Date(blogdetails.created_at);

                        // Define months array
                        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

                        // Get day, month, and year
                        const day = createdAtDate.getDate();
                        const monthIndex = createdAtDate.getMonth();
                        const year = createdAtDate.getFullYear();

                        // Format the date string
                        const formattedDate = `${day} ${months[monthIndex]} ${year}`;
                        return (
                           <div className="col-md-4  mt-3">
                              <Link to={`/blogs/${blogdetails.slug}`}>
                                 <div className="card" style={{ width: '100%' }}>
                                    <img src={blogdetails.thumbnail} loading="lazy" className="w-100 blog" />
                                    <div className="card-body crdr1 ">
                                       <div className="row">
                                          <div className="col-md-12 col-lg-12 col-12 ">
                                             <b
                                                style={{ textTransform: 'capitalize' }}
                                                title={blogdetails.title ? blogdetails.title : 'Null'}

                                             >
                                                {blogdetails.title
                                                   ? blogdetails.title.split(' ').slice(0, 8).join(' ') +
                                                   (blogdetails.title.split(' ').length > 8 ? '...' : '')
                                                   : 'Null'}
                                             </b>
                                             {/* <b style={{ textTransform: 'capitalize' }}>{blogdetails.title ? blogdetails.title : 'Not found'}</b> */}
                                             <div className="d-flex justify-content-between">
                                                <p className="py-2">{formattedDate}</p>
                                                <p className="py-2"><GrView />&nbsp;{blogdetails.views}</p>
                                             </div>
                                             {/* <p className="next-para">{truncatedContent}...      <Link to={`/blog-details/${blogdetails.slug}`}> View More       </Link></p> */}
                                             <div dangerouslySetInnerHTML={{ __html: truncatedContent || '' }} />..<Link to={`/blogs/${blogdetails.slug}`}> View More       </Link>
                                          </div>

                                       </div>
                                    </div>
                                 </div>
                              </Link>
                           </div>
                        )
                     })
                     }
                  </div>
               )}
               <div className="text-center mt-3">
                  <a href="/blogs">
                 
                   
                    
                     <button type="button" className="btn btn-outline-primary">View All Latest Blogs <MdOutlineReadMore /></button></a>
               </div>
            </div>

         </div>

         <div className="mb-5 testersection d-none" >

            <div className="container">
               <h3 className="text-center"><span className="latest stydentsays"></span> Our Students have to say</h3>




               {isLoading ? (
                  <div className='text-center'>

                     <MagnifyingGlass
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="magnifying-glass-loading"
                        wrapperStyle={{}}
                        wrapperClass="magnifying-glass-wrapper"
                        glassColor="#c0efff"
                        color="#e15b64"
                     />
                  </div>
               ) : (

                  <div className='row mt-4'>
                     <OwlCarousel className="owl-theme" {...optionstest} >
                        {testimonial.map((testimonial) => {
                           return (

                              <div class="card testerbox mt-4 mb-4">
                                 <div class="card-body ">
                                    <div className="d-flex">
                                       <div><img src={woman} width="" height="" loading="lazy" alt="NayeeDisha" /></div>
                                       &nbsp;&nbsp;&nbsp;
                                       <div className="mt-3">
                                          <h5>{testimonial.author}</h5>

                                       </div>
                                    </div>
                                    <p className="pb-4">
                                       {testimonial.content}
                                    </p>
                                 </div>
                              </div>

                           )
                        })
                        }
                     </OwlCarousel>
                  </div>

               )}

            </div>
         </div>






         {/*  */}

      </Fragment>
   );
};
export default Home;