import { React, useEffect, useState, Component, Fragment } from "react";
import aboutusbanner from '../img/aboutusbanner.png';
import {Bs0CircleFill, Bs1CircleFill, Bs2CircleFill, Bs3CircleFill, Bs4CircleFill, Bs5CircleFill, Bs6CircleFill,Bs7CircleFill,Bs8CircleFill,Bs9CircleFill } from "react-icons/bs";
import Admissionimg from '../../img/admission.svg'
import { BsGlobe } from "react-icons/bs";
import OwlCarousel from "react-owl-carousel";
import "../../../node_modules/owl.carousel/dist/assets/owl.carousel.css";
import "../../../node_modules/owl.carousel/dist/assets/owl.theme.default.css";
import { Spinner } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import lister1 from '../img/lister1.jpg';
import iconlister from '../img/iconlister.jpg';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
const OMSK = () => {
    const options = {
        loop: true,
        margin: 10,
        items: 1,
        autoplay: true,
        responsive: {
           0: {
              items: 1,
              nav:true,
              
           },
           600: {
              items: 3,
           },
           1000: {
              items: 4,
           },
        },
     };
    const [universities, setUniversities] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
       fetch('https://webcrmapi.nayeedisha.in/api/university-details')
          .then((response) => response.json())
          .then((data) => {
             // Assuming 'results' contains the array of universities
             setUniversities(data);
             setIsLoading(false);
         
          })
          .catch((error) => {
             setIsLoading(false);
             console.error('Error fetching data:', error);
          });
    }, []);
    return (
        <>



            {/* section1 */}

            <div className='abt-section pt-5 pb-5'>


                <div className='container'>

                    <h3>MBBS in OMSK State Medical University, Russia</h3>

                    <div className='bds'></div>
                    <div id='brdrs'></div>

                    {/* <p>It is a long established fact that a reader.</p> */}


                </div>


            </div>




            <div className='container abt-2 mt-5 mb-5'>


                <p className='mb-4'>Welcome to Nayee Disha, your trusted companion on the journey to realizing your dream of pursuing an esteemed medical education abroad! We take immense pride in introducing OMSK State Medical University, a distinguished institution nestled in the heart of Russia. With its unwavering commitment to excellence, OMSK State Medical University offers Indian students an exceptional opportunity to embark on a transformative educational path in the field of medicine. Renowned for its world-class curriculum, state-of-the-art facilities, and globally recognized faculty, this esteemed university serves as a gateway to a successful medical career. Join us as we explore the captivating world of MBBS education at OMSK State Medical University, where boundless possibilities await you, and your aspirations are nurtured with utmost care and expertise.</p>
               
                <hr></hr>
                <div className='py-3 rounded'>

                    <div className='container mt-3'>
                        <h3 className='border_before'>Advantages of MBBS in OMSK State Medical University, Russia</h3>
                        <div className='row'>
                            <div className='col-md-3 mt-4 '>
                                <div className='bg-coloringrideant card-height  p-3 rounded'>
                                    <b className='f-19'>Global Recognition</b>
                                    <p className='f-17'>Acclaimed Degrees Opening the International Doors and Precious Chances</p>
                                </div>

                            </div>
                            <div className='col-md-3 mt-4 '>
                                <div className='bg-coloringrideant card-height p-3 rounded'>
                                    <b className='f-19'>Affordable Education</b>
                                    <p className='f-17'>Cost-Effective MBBS Programs without Compromising Quality and Rigour</p>
                                </div>

                            </div>
                            <div className='col-md-3 mt-4 '>
                                <div className='bg-coloringrideant card-height p-3 rounded'>
                                    <b className='f-19'>Experienced Faculty</b>
                                    <p className='f-17'>Renowned Professors Ensuring Comprehensive Medical Education Is Provided</p>
                                </div>

                            </div>
                            <div className='col-md-3 mt-4 '>
                                <div className='bg-coloringrideant card-height p-3 rounded'>
                                    <b className='f-19'>Cultural Diversity</b>
                                    <p className='f-17'>Exposure to Multicultural Environment Enhancing Global Perspective and Exposure</p>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className='container mb-4 mt-3'>
                        <div className='row'>
                            <div className='col-md-3 mt-4 '>
                                <div className='bg-coloringrideant card-height p-3 rounded'>
                            
                                

                                    <b className='f-19'>Advanced Infrastructure</b>
                                    <p className='f-17'>State-of-the-Art Facilities Are Provided Cutting-Edge Learning Opportunities</p>
                                </div>

                            </div>
                            <div className='col-md-3 mt-4 '>
                                <div className='bg-coloringrideant card-height p-3 rounded'>
                                    <b className='f-19'>High-Quality Teaching</b>
                                    <p className='f-17'>Rigorous Curriculum and Practical Training for Competent Medical Professionals</p>
                                </div>

                            </div>
                            <div className='col-md-3 mt-4 '>
                                <div className='bg-coloringrideant card-height p-3 rounded'>
                                    <b className='f-19'>English-Medium</b>
                                    <p className='f-17'>Language Barrier-Free Education Facilitating Easy Learning and Communication</p>
                                </div>

                            </div>
                            <div className='col-md-3 mt-4 '>
                                <div className='bg-coloringrideant card-height p-3 rounded'>
                                    <b className='f-19'>Career Opportunities</b>
                                    <p className='f-17'>Seamless Pathway to Explore Employment Options in Russia and Beyond.</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>



                <hr></hr>




            </div>
            <div className='container'>
                <h3 className='border_before'>Facts about OMSK State Medical University, Russia</h3>
                <div className='row'>
                    <div className='col-md-12'>
                        <ul className='list-none mt-2'>
                            <li className='mt-1 '><Bs1CircleFill />&nbsp; Prestigious Reputation: OMSK State Medical University boasts a prestigious reputation in Russia and worldwide, renowned for its excellence in medical education.</li>
                            <li className='mt-2 '> <Bs2CircleFill /> &nbsp; Recognized by WHO and MCI: The university is listed in the World Health Organization (WHO) directory of medical schools and holds recognition from the Medical Council of India (MCI), ensuring that your degree is globally recognized and accepted.</li>
                            <li className='mt-2 '> <Bs3CircleFill />&nbsp; Comprehensive Curriculum: OMSK State Medical University offers a comprehensive and well-structured curriculum that covers all essential aspects of medical education, providing a strong foundation for your future medical career.</li>
                            <li className='mt-2 '> <Bs4CircleFill /> &nbsp; Modern Facilities: The university provides modern and well-equipped facilities, including state-of-the-art laboratories, simulation centers, and research facilities, enabling students to gain hands-on experience with the latest medical technologies.</li>
                            <li className='mt-2 '> <Bs5CircleFill /> &nbsp; Experienced Faculty: OMSK State Medical University boasts a highly experienced faculty, consisting of accomplished medical professionals, researchers, and professors who are dedicated to nurturing the talents and potential of their students.</li>
                            <li className='mt-2 '> <Bs6CircleFill /> &nbsp; Clinical Training: The university has collaborations with prestigious medical institutions and hospitals, providing ample opportunities for clinical exposure and hands-on training to enhance practical skills.</li>
                            <li  className='mt-2 '><Bs7CircleFill /> &nbsp; Research Opportunities: OMSK State Medical University encourages students to engage in research activities, fostering a culture of innovation and providing opportunities to contribute to advancements in medical science.</li>
                            <li  className='mt-2 '><Bs8CircleFill /> &nbsp; Multicultural Environment: Studying at OMSK State Medical University exposes you to a vibrant multicultural environment, where you can interact with students from various countries, broadening your horizons and fostering cross-cultural understanding.</li>
                            <li  className='mt-2 '><Bs9CircleFill /> &nbsp; Affordable Tuition Fees: OMSK State Medical University offers competitive tuition fees, making it an affordable option for Indian students seeking quality medical education abroad.</li>
                            
                        </ul>
                    </div>

                </div>
            </div>
<hr></hr>
<div className='container'>
                <h3 className='border_before'>Admission Process and Documents Needed</h3>
                <div className='row'>
                    <div className='col-md-12'>
                      
                    </div>

                </div>
            </div>

<div className='container'>

<img src={Admissionimg} alt="img" loading="lazy"  className='w-100 mt-3'/>
</div>

<br></br>

        </>
    );
};

export default OMSK;
