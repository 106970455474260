import { React, useEffect, useState, Component, Fragment } from "react";
import aboutusbanner from '../img/aboutusbanner.png';
import { Bs0CircleFill, Bs1CircleFill, Bs2CircleFill, Bs3CircleFill, Bs4CircleFill, Bs5CircleFill, Bs6CircleFill, Bs7CircleFill, Bs8CircleFill, Bs9CircleFill } from "react-icons/bs";
import Admissionimg from '../../img/admission.svg'
import { BsGlobe } from "react-icons/bs";
import OwlCarousel from "react-owl-carousel";
import "../../../node_modules/owl.carousel/dist/assets/owl.carousel.css";
import "../../../node_modules/owl.carousel/dist/assets/owl.theme.default.css";
import { Spinner } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import lister1 from '../img/lister1.jpg';
import iconlister from '../img/iconlister.jpg';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { Helmet } from "react-helmet";
const IndiaEngineering = () => {
  const options = {
    loop: true,
    margin: 10,
    items: 1,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
        nav: true,

      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };
  const [universities, setUniversities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    fetch('https://web.webcrmapi.nayeedisha.in/api/university-details')
      .then((response) => response.json())
      .then((data) => {
        // Assuming 'results' contains the array of universities
        setUniversities(data);
        setIsLoading(false);
     
      })
      .catch((error) => {
        setIsLoading(false);
        console.error('Error fetching data:', error);
      });
  }, []);
  return (
    <>


<div className="application">
<Helmet>
    <meta charSet="utf-8" />
    <title>Check out the list of top engineering colleges in India with their fees, NIRF ranking, highlights, courses, and many more at Nayeedisha.in</title>

    <link rel="canonical" href="https://nayeedisha.in" />
</Helmet>

</div>
      {/* section1 */}

      <div className='abt-section pt-5 pb-5'>


        <div className='container'>

          <h3>Engineering In India</h3>

          <div className='bds'></div>
          <div id='brdrs'></div>

          {/* <p>It is a long established fact that a reader.</p> */}


        </div>


      </div>




      <div className='container abt-2 mt-5 mb-5'>


        <p className='mb-4'>Engineering has become a rapidly growing sector in all over the world and is playing an essential role in shaping the economic structure and developing societies. In the current scenario, the engineering sector is a witness to emerging technology. The opportunity and scope of engineering is bright but, it always demands skilled proffesionals for specific domains. Choosing to pursue engineering not only provides you with a sustainable career opportunities but, also offers immense financial growth with a reputed job profile. Any student can do B-Tech/BE after completing the 12th standard and qualify for the entrance exams namely JEE, BITSAT, VITEEE or any other state-level entrance examinations in order to seek admission in various engineering colleges in India. You may choose to purrsue engineering in any stream such as Computer Science, Information Technology, Mechanical, Civil , Electrical, and Bio-Medical Engineering.
</p>

        <hr></hr>
        <div className='py-3 rounded'>

          <div className='container mt-3'>
            <h3 className='border_before'>Benefits of Engineering in India (B. Tech/BE): </h3>
            <div className='row'>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height  p-3 rounded'>
                  <b className='f-19'>Better Career Opportunity</b>
                  <p className='f-17'>As compared to other graduate degrees, a B. Tech/BE degree exposes better career opportunities</p>
                </div>

              </div>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>
                  <b className='f-19'>Job Satisfaction:</b>
                  <p className='f-17'>Engineers get jobs in prestigious organizations with high levels of job satisfaction</p>
                </div>

              </div>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>
                  <b className='f-19'>Handsome Salary Packages: </b>
                  <p className='f-17'>As with other jobs, B. Tech earns better salary packages</p>
                </div>

              </div>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>
                  <b className='f-19'>Security</b>
                  <p className='f-17'>It is a profitable profession where engineers get security and stability in their jobs. </p>
                </div>

              </div>
            </div>
          </div>

          <div className='container mb-4 mt-3'>
            <div className='row'>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>



                  <b className='f-19'>Reputation</b>
                  <p className='f-17'>Engineers achieve higher reputation jobs in top-notch companies or organizations. </p>
                </div>

              </div>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>
                  <b className='f-19'>Creativity</b>
                  <p className='f-17'> Engineers will get into the creative profession because they face rapid or emerging changes</p>
                </div>

              </div>
             
            </div>
          </div>
        </div>



        <hr></hr>




      </div>
      <div className='container'>
        <h3 className='border_before'>Here Are Some Major Facts About Engineering in India</h3>
        <div className='row'>
          <div className='col-md-12'>
            <ul className='list-none mt-2'>
              <li className='mt-1 '><Bs1CircleFill />&nbsp; In India, more than 1.5 milion students graduate every year as engineers. </li>
              <li className='mt-2 '> <Bs2CircleFill /> &nbsp; India houses more than 2500 engineering colleges, 1400 poytechnics and 200 schools of planning and architecture </li>
              <li className='mt-2 '> <Bs3CircleFill />&nbsp; As per NAASCOM, only 15-18% of the engineering graduates get employed in the core engineering industry. </li>
              <li className='mt-2 '> <Bs4CircleFill /> &nbsp; In India, students get the chance to study in world class institutes such as IITs, NITs, and IIITs. </li>
              <li className='mt-2 '> <Bs5CircleFill /> &nbsp; Sir Mokshagundam Visvesvaraya is the father of Indian engineering. </li>
              <li className='mt-2 '> <Bs6CircleFill /> &nbsp; Any student can pursue B-tech/BE in India if they had PCM and PCB in their 12th standard after appearing for JEE or other entrance examinations.</li>
         
            </ul>
          </div>

        </div>
      </div>
      <hr></hr>
      <div className='container'>
        <h3 className='border_before'>Admission Process and Documents Needed</h3>
        <div className='row'>
          <div className='col-md-12'>

          </div>

        </div>
      </div>

      <div className='container'>

        <img src={Admissionimg} alt="img" loading="lazy" className='w-100 mt-3' />
      </div>

      <br></br>
   
    </>
  );
};

export default IndiaEngineering;






