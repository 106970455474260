import { React, useEffect, useState, Component, Fragment } from "react";
import aboutusbanner from '../img/aboutusbanner.png';
import { Bs0CircleFill, Bs1CircleFill, Bs2CircleFill, Bs3CircleFill, Bs4CircleFill, Bs5CircleFill, Bs6CircleFill, Bs7CircleFill, Bs8CircleFill, Bs9CircleFill } from "react-icons/bs";
import Admissionimg from '../../img/admission.svg'
import { BsGlobe } from "react-icons/bs";
import OwlCarousel from "react-owl-carousel";
import "../../../node_modules/owl.carousel/dist/assets/owl.carousel.css";
import "../../../node_modules/owl.carousel/dist/assets/owl.theme.default.css";
import { Spinner } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import lister1 from '../img/lister1.jpg';
import iconlister from '../img/iconlister.jpg';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
const UniversityShanghaiChina = () => {
  const options = {
    loop: true,
    margin: 10,
    items: 1,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
        nav: true,

      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };
  const [universities, setUniversities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    fetch('https://webcrmapi.nayeedisha.in/api/university-details')
      .then((response) => response.json())
      .then((data) => {
        // Assuming 'results' contains the array of universities
        setUniversities(data);
        setIsLoading(false);
       
      })
      .catch((error) => {
        setIsLoading(false);
        console.error('Error fetching data:', error);
      });
  }, []);
  return (
    <>



      {/* section1 */}

      <div className='abt-section pt-5 pb-5'>


        <div className='container'>

          <h3>MBBS in Fudan University Shanghai Medical College, China</h3>

          <div className='bds'></div>
          <div id='brdrs'></div>

          {/* <p>It is a long established fact that a reader.</p> */}


        </div>


      </div>




      <div className='container abt-2 mt-5 mb-5'>


        <p className='mb-4'>Welcome to the page dedicated to MBBS in Fudan University Shanghai Medical College, China. Situated in the dynamic city of Shanghai, Fudan University Shanghai Medical College stands as a prestigious institution renowned for its excellence in medical education and research. As a leading medical school in China, Fudan University Shanghai Medical College offers a comprehensive and internationally recognized MBBS program that prepares students for a successful career in the field of medicine. With a commitment to fostering global perspectives, cutting-edge facilities, and a vibrant learning environment, this page provides an in-depth exploration of the exceptional opportunities and experiences awaiting students pursuing MBBS at Fudan University Shanghai Medical College.</p>

        <hr></hr>
        <div className='py-3 rounded'>

          <div className='container mt-3'>
            <h3 className='border_before'>Advantages of Fudan University Shanghai Medical College, China</h3>
            <div className='row'>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height  p-3 rounded'>
                  <b className='f-19'>Global Recognition</b>
                  <p className='f-17'>Acclaimed Degrees Opening the International Doors and Precious Chances</p>
                </div>

              </div>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>
                  <b className='f-19'>Affordable Education</b>
                  <p className='f-17'>Cost-Effective MBBS Programs without Compromising Quality and Rigour</p>
                </div>

              </div>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>
                  <b className='f-19'>Experienced Faculty</b>
                  <p className='f-17'>Renowned Professors Ensuring Comprehensive Medical Education Is Provided</p>
                </div>

              </div>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>
                  <b className='f-19'>Cultural Diversity</b>
                  <p className='f-17'>Exposure to Multicultural Environment Enhancing Global Perspective and Exposure</p>
                </div>

              </div>
            </div>
          </div>

          <div className='container mb-4 mt-3'>
            <div className='row'>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>



                  <b className='f-19'>Advanced Infrastructure</b>
                  <p className='f-17'>State-of-the-Art Facilities Are Provided Cutting-Edge Learning Opportunities</p>
                </div>

              </div>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>
                  <b className='f-19'>High-Quality Teaching</b>
                  <p className='f-17'>Rigorous Curriculum and Practical Training for Competent Medical Professionals</p>
                </div>

              </div>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>
                  <b className='f-19'>English-Medium</b>
                  <p className='f-17'>Language Barrier-Free Education Facilitating Easy Learning and Communication</p>
                </div>

              </div>
              <div className='col-md-3 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>
                  <b className='f-19'>Career Opportunities</b>
                  <p className='f-17'>Seamless Pathway to Explore Employment Options in Russia and Beyond.</p>
                </div>

              </div>
            </div>
          </div>
        </div>



        <hr></hr>




      </div>
      <div className='container'>
        <h3 className='border_before'>Facts about Fudan University Shanghai Medical College, China</h3>
        <div className='row'>
          <div className='col-md-12'>
            <ul className='list-none mt-2'>
              <li className='mt-1 '><Bs1CircleFill />&nbsp; Established Prestige: Fudan University Shanghai Medical College has a rich history dating back to 1927, making it one of the oldest and most prestigious medical colleges in China.</li>
              <li className='mt-2 '> <Bs2CircleFill /> &nbsp; Global Recognition: The college is highly regarded both nationally and internationally for its outstanding reputation in medical education and research.</li>
              <li className='mt-2 '> <Bs3CircleFill />&nbsp; Comprehensive MBBS Program: Fudan University Shanghai Medical College offers a comprehensive Bachelor of Medicine and Bachelor of Surgery (MBBS) program designed to equip students with the knowledge and skills necessary for a successful medical career.</li>
              <li className='mt-2 '> <Bs4CircleFill /> &nbsp; Rigorous Curriculum: The MBBS program at Fudan University Shanghai Medical College follows a rigorous curriculum that combines theoretical knowledge, practical training, and clinical exposure, ensuring students receive a well-rounded medical education.</li>
              <li className='mt-2 '> <Bs5CircleFill /> &nbsp; State-of-the-Art Facilities: The college is equipped with modern facilities, advanced laboratories, simulation centers, and affiliated teaching hospitals that provide students with hands-on training and exposure to cutting-edge medical technologies.</li>
              <li className='mt-2 '> <Bs6CircleFill /> &nbsp; Expert Faculty: Fudan University Shanghai Medical College boasts a dedicated and highly qualified faculty, comprising experienced professors and medical professionals who are committed to delivering quality education and mentorship to students.</li>
              <li className='mt-2 '><Bs7CircleFill /> &nbsp; Research Excellence: The college is at the forefront of medical research, actively engaging in groundbreaking studies across various disciplines, including genetics, neurology, oncology, infectious diseases, and public health.</li>
              <li className='mt-2 '><Bs8CircleFill /> &nbsp; International Collaboration: Fudan University Shanghai Medical College has established collaborative partnerships with renowned international institutions, fostering academic exchanges, joint research projects, and opportunities for international exposure.</li>
              <li className='mt-2 '><Bs9CircleFill /> &nbsp; Global Perspective: The college promotes a global perspective in medical education, offering opportunities for students to participate in international exchange programs and gain insights into global healthcare practices.</li>

            </ul>
          </div>

        </div>
      </div>
      <hr></hr>
      <div className='container'>
        <h3 className='border_before'>Admission Process and Documents Needed</h3>
        <div className='row'>
          <div className='col-md-12'>

          </div>

        </div>
      </div>

      <div className='container'>

        <img src={Admissionimg} alt="img" loading="lazy" className='w-100 mt-3' />
      </div>

      <br></br>

    </>
  );
};

export default UniversityShanghaiChina;






