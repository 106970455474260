import { React, useState, useEffect } from 'react';
import nayidishalogofooter from '../img/nayidishalogofooter.png';
import nayidishafooter from '../img/nayidishafooter.svg'

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import otp from './otp.svg';
import sideimg from './side-img.png';
import { Link } from "react-router-dom";
import nayeedishafooterfin from '../img/nayeedishafooter-fin.svg';
import { IoIosCloseCircle } from "react-icons/io";
import HorizontalLinearStepper from '../pages/registration-steper/horizontalLinearStepper'
import { Button } from 'react-bootstrap';
import CookieConsent, { Cookies, getCookieConsentValue } from "react-cookie-consent";
import apiUrl from '../setting/apiConfig'
import { FaCookieBite } from "react-icons/fa";
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import { SiReacthookform } from "react-icons/si";
import { PiDownload } from "react-icons/pi";
import whatsapp from '../img/whatsapp.svg'
import { FaPhone } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import Skeleton from 'react-loading-skeleton'; // If using react-loading-skeleton
import 'react-loading-skeleton/dist/skeleton.css';
import Marquee from 'react-fast-marquee';
import ReactSearchBox from "react-search-box";
console.log(getCookieConsentValue(Cookies));

const Footer = () => {
  const handleAccept = () => {
    console.log('User accepted cookies.');
  };



  useEffect(() => {
    // Initialize Bootstrap tooltip
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-toggle="tooltip"]'))
    const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new window.bootstrap.Tooltip(tooltipTriggerEl)
    })
  }, []);


  const handleDecline = () => {
    console.log('User declined cookies.');
  };



  const [universities, setUniversities] = useState([]);
  const [newsdetails, setNewsdetails] = useState([]);
  const [testimonial, setTestimonial] = useState([]);
  const [exams, setExams] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = useState(0);
  const [nepalUniversities, setNepalUniversities] = useState([]);
  const [indiaUniversities, setIndiaUniversities] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const universityResponse = await fetch(apiUrl + 'university-details');
        const universityData = await universityResponse.json();

        // Filter universities for Russia and Nepal
        const russiaUniversities = universityData.filter(university => university.country === 'Russia');
        const nepalUnis = universityData.filter(university => university.country === 'Nepal');
        const indiaUnis = universityData.filter(university => university.country === 'India');

        setUniversities(russiaUniversities);
        setNepalUniversities(nepalUnis);
        setIndiaUniversities(indiaUnis);

        console.log('Footer University Data:', russiaUniversities);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);



  const searchData = [
    {
      key: "ani",
      value: "Aniket",
      url: "https://word.com/"
    },
    {
      key: "jane",
      value: "Jane Doe",
      url: "https://facebook.com/"
    },
    {
      key: "mary",
      value: "Mary Phillips",
      url: "https://example.com/mary"
    },
    {
      key: "robert",
      value: "Robert",
      url: "https://example.com/robert"
    },
    {
      key: "karius",
      value: "Karius",
      url: "https://example.com/karius"
    }
  ];
  const onSelect = (record) => {
    window.location.href = record.url;
  
};
const renderOption = (record, index) => {
  return (
    <div key={index} onClick={() => onSelect(record)}>
      <span>{record.value}</span>
      <span style={{ marginLeft: '5px', color: '#999' }}>{record.url}</span>
    </div>
  );
};



  return (
    <>


{/* <div
        className="modal fade py-5"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" style={{ maxWidth: '90%', margin: 'auto',padding: '25px' }}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Start Searching...</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <ReactSearchBox
            placeholder="Search for Universites , Colleges, Exams, News and more"
            data={searchData}
            onSelect={onSelect}
            renderOption={renderOption}
          />
          <h3 className='h4 p-2'>TOP SEARCHES</h3>
          </div>
        </div>
      </div> */}
 


      <div className='footer1 p-5'>
      <div className='container'>
      <h5 className='mt-2 mb-4 text-white study_abroad '>Study Abroad Option</h5>
     

          <div className='row mt-2'>
   <div className='col-md-3 col-6'><a href='mbbs-in-russia'><h5>MBBS in Russia</h5></a></div>
    <div className='col-md-3 col-6'><a href='mbbs-in-india'> <h5>MBBS in India</h5></a></div>

    <div className='col-md-3 col-6'><a href='#'> <h5>MBBS in Nepal</h5></a></div>
    <div className='col-md-3 col-6'><a href='#'> <h5>MBBS in Philippines</h5></a></div>
   
  </div>

  <div className='row mt-2'>
    <div className='col-md-3 col-6'><a href='#'> <h5>MBBS in China</h5></a></div>
    <div className='col-md-3 col-6'><a href='#'> <h5>MBBS in Bangladesh</h5></a></div>
   <div className='col-md-3 col-6'>  <a href='#'><h5>MBBS in Kazakhstan</h5></a></div>
   <div className='col-md-3 col-6'>  <a href='#'><h5>MBBS in Kyrgyzstan</h5></a></div>
  </div>

</div>


      

      </div>

     

      <div className="footer3 ">

        <div className='container'>

          <div className='row'>

            <div className='col-md-3 col-12'>

              {/* <a className="navbar-brand foot-res1" href="/"><img src={nayeedishafooterfin} alt="" style={{ width: "200px" }} />

              </a> */}
              <h5 className='mt-2'>Our Office Address</h5>
              <div className='pt-2 foot-res2'>
                <p><b>Head office -</b> Suite 1405, 1407, 14th Floor,T3, Techzone-4, NX One, Greater Noida West, Uttar Pradesh 201306</p>
                <a href="tel:+91 8860031900"> <p><FaPhone /> +91 8860031900</p></a>

                <a href="mailto:info@nayeedisha.in">   <p><MdEmail /> info@nayeedisha.in</p></a>



              </div>

            </div>


            <div className='col-md-3 pt-2 col-12'>

              <br></br>
              <div className='pt-1 foot-res2 mt-2'>
                <p><b>Regional Office -</b>
                  Nayee Disha Education
                , B-361, Sidharth Enclave, taramanadal,
                  Near GDA office, Gorakhpur</p>

                <a href="tel:+91 8860031900"> <p><FaPhone /> +91 8860031900</p></a>

                <a href="mailto:info@nayeedisha.in">   <p><MdEmail /> info@nayeedisha.in</p></a>
              </div>

            </div>



            {/* <div className='col pt-2'>

              <h5>Tools & Research</h5>
              <div className='pt-2'>
                <p>Career Compass</p>

                <p>Write a review</p>

                <p>QnA Forum</p>

                <p>Predictor</p>

                <p>Unit Calculator</p>

              </div>

            </div> */}


            <div className='col-md-3 col-6 pt-2'>

              <h5>Quick Links</h5>
              <div className='pt-2'>
                <Link to={"/about-us"} ><p>About us</p></Link>

                <Link to={"/contact-us"} ><p>Contact us</p></Link>

                <Link to={"/blogs"} ><p>Latest Blogs</p></Link>
                <Link to={"/we-provide"} ><p>We Provide</p></Link>
              </div>

            </div>


            <div className='col-md-3 col-6 pt-2'>

              <h5>Follow</h5>
              <div className='pt-2'>
                <a href='https://www.facebook.com/people/NayeeDisha-Study-Abroad/61559962673504/' target="_blank"><p>Facebook</p></a>

                <a href='https://x.com/NayeeInfo' target="_blank"><p>Twitter</p></a>

                <a href='https://www.instagram.com/nayeedisha_official/?hl=en' target="_blank"> <p>Instagram</p></a>

                <a href='https://www.linkedin.com/in/nayee-disha-ab3a55308' target="_blank"><p>Linkedin</p></a>


              </div>
            </div>



          </div>

        </div>


      </div>










      {/* modal */}

      <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">

        <div className=" modal-dialog modal-xl">
          <Button variant="secondary closebutton"><IoIosCloseCircle className='close_icon_width' /></Button>
          <div className="modal-content">
            <HorizontalLinearStepper />
          </div>

        </div>
      </div>



      <div className='text-white py-3 footer3'>
        <div className='container'>
          <div className='row'>
          
            <div className='col-lg-7 col-md-12'>
              <div className='footer_Privacy d-flex '>
                <a href='/privacy-policy'> <p>Privacy Policy</p> </a>
                &nbsp; |   &nbsp;
                <a href='terms-and-conditions'><p>Terms and conditions</p></a>
                {/* &nbsp;  <span className='mobilesection-hide_new'>|</span>  &nbsp;
                <a href='tel:+91 8860031900'><p>Contact our education counsellors  : +91 8860031900</p></a> */}
              </div>

            </div>
            <div className='col-lg-5 col-md-12 text-center footerbottom'>
              <p>Copyright © 2024 All Rights Reserved.</p>
            </div>
          </div>
        </div>


      </div>



      {/* end modal */}








      {/* SECOND MODAL */}
      {/* modal start */}



      {/* 


      {/* modal end */}
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        cookieName="NayeedishaEdu"
        onAccept={handleAccept}
        onDecline={handleDecline}
        style={{
          background: "#333",
          color: "#fff",
          padding: "20px",
          fontSize: "16px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          zIndex: "1000",
          borderTop: "3px solid #FFD700",
        }}
        buttonStyle={{
          background: "#FFD700",
          color: "#000",
          fontSize: "14px",
          padding: "10px 20px",
          borderRadius: "5px",
          cursor: "pointer",
        }}
        declineButtonStyle={{
          background: "#ff4d4d",
          color: "#fff",
          fontSize: "14px",
          padding: "10px 20px",
          borderRadius: "5px",
          cursor: "pointer",
        }}
        expires={365}
        enableDeclineButton
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <FaCookieBite size={24} style={{ marginRight: "10px", color: "#FFD700" }} />
          <span>This website uses cookies to enhance the user experience.</span>
        </div>
      </CookieConsent>

      <div id="fixed_quick_enquiry">
    <p className="callvideo"><a  role="button" className="btn btn-primary btn-lg rrighbtn"  data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    data-bs-whatever="@mdo">Admissions 25-26 </a></p>
</div>

      {/*  */}

      <a href="https://wa.me/+918860031900" className='float' target="_blank">
        <img src={whatsapp} className="whatspp" loading="lazy" alt='Nayeedisha' />
      </a>

  <div className="bottom-nav text-white h4 wave_talk" data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    data-bs-whatever="@mdo">
     
        Admission Open <span className="year"> &nbsp; 25-26</span>
    
    </div>

    
  
    </>
  );
};

export default Footer;
