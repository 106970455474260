import React from 'react';
import Notfount from '../../img/404.svg'
const NotFoundPage = () => {
  return (
    <div className='py-5 px-5 text-center'>
     <img src={Notfount} alt="Not Found" loading="lazy" className="w-50 mt-5"  />
    </div>
  );
};

export default NotFoundPage;