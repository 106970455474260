import React, { useEffect, useState ,useRef } from 'react';
import { useComparison } from './CompareContext/CompareContext';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useParams } from 'react-router-dom';
import Badge from 'react-bootstrap/Badge';
import Modal from 'react-bootstrap/Modal';
import { HiMiniInformationCircle } from "react-icons/hi2";
import { IoIosCall } from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa";
import Accordion from 'react-bootstrap/Accordion';
import { IoReaderOutline } from "react-icons/io5";
import { GoPlusCircle } from "react-icons/go";
import Table from 'react-bootstrap/Table';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IoCallOutline } from "react-icons/io5";
import Button from '@mui/material/Button';
import PhoneInput from "react-phone-input-2";
import Swal from 'sweetalert2';
const getCookie = (name) => {
    let value = `; ${document.cookie}`;
    let parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
 }
 // Mock qp function
const qp = (event, label) => {
    console.log(`Event: ${event}, Label: ${label}`);
  };
const CompareColleges = () => {
    const { comparisonData, setComparisonData } = useComparison();
    const { colleges } = useParams(); // Extract the college names from the URL

    // Modal state management
    const [modalShow, setModalShow] = useState(false);

    const [selectedUniversity, setSelectedUniversity] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [applyNow, setApplyNow] = useState(false);
    const [isLoading, setLoading] = useState(true);
    // Safeguard against undefined or malformed parameter
    const collegeNames = colleges ? colleges.split('-vs-') : [];
    const formRef = useRef(null);
    useEffect(() => {
        // Retrieve data from local storage if it's not available in context
        if (!comparisonData) {
            const storedData = localStorage.getItem('comparisonData');
            if (storedData) {
                setComparisonData(JSON.parse(storedData));
            }
        }
    }, [comparisonData, setComparisonData]);

    
    // Extract universities and courses from comparisonData
    const universities = comparisonData ?
        Object.values(comparisonData).map(item => item.UniversityInfo)
        : [];

    const courses = comparisonData ?
        Object.values(comparisonData).flatMap(item => item.Courses || [])
        : [];

    if (!universities.length && !courses.length) {
        return <div>No data available for comparison.</div>;
    }

    const handleBadgeClick = (university) => {
        setSelectedUniversity(university);
        setModalShow(true);
    };

    const handleBadgeClickApply = () => {   
        setModalShow(true);
    };
    

    // new Apply Now Submit

    const submitForm = async (event) => {
        event.preventDefault();
        
        // Track event using qp function
        qp('track', 'Generic');
    
        const formData = new FormData(event.target);
        formData.append('current_url', getCookie("url"));
        formData.append('campaign', 'mbbs-in-russia-from-main-page');
        try {
          setLoading(true);
    
          const response = await fetch('https://webcrmapi.nayeedisha.in/api/landingpage-leads', {
            method: 'POST',
            body: JSON.stringify(Object.fromEntries(formData)),
            headers: {
              'Content-Type': 'application/json',
            },
          });
    
          const responseData = await response.json();
    
          if (!response.ok) {
              console.log(responseData.error);
            throw new Error(JSON.stringify(responseData.error) || 'Failed to submit form');
          }
    
          setLoading(false);
          setSuccessMessage(responseData.message);
          setErrorMessage(null);
    
          formRef.current.reset(); // Reset the form
    
          Swal.fire({
            title: "Success!",
            text: responseData.message,
            icon: "success",
          }).then(() => {
            if (window.gtag) {
              console.log("Logging Google Analytics event...");
              window.gtag('event', 'submit', {
                'event_category': 'Form',
                'event_label': 'Admission Form',
                'value': 'text_success'
              });
              console.log("Google Analytics event submitted.");
            } else {
              console.warn("gtag function is not available.");
            }
    
            setTimeout(() => {
              setSuccessMessage(null);
            }, 5000);
          });
        } catch (error) {
          const {phone_number} = JSON.parse(error?.message)
          const err = phone_number.join('');
          setLoading(false);
          setSuccessMessage(null);
          setErrorMessage(err|| 'Failed to submit form');
   
          Swal.fire({
            title: "Error!",
            text: err|| 'Failed to submit form',
            icon: "error",
            showConfirmButton: false,
            timer: 1500
          });
        }
      };
    
      let url = window.location.href;
      document.cookie = `url=${url}`;
  
    const tooltip = (
        <Tooltip id="tooltip">
            <strong>Feel free to adjust it based on the context and specific needs of your application!</strong>
        </Tooltip>
    );


    return (
        <>
            <div className='container pt-3'>
                <Breadcrumb className='CompareUniversities'>
                    <Breadcrumb.Item href="#" id="crumb">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active id="crumb">Compare Universities</Breadcrumb.Item>
                </Breadcrumb>
            </div>

            <div className='container'>

                <Table striped bordered hover responsive="sm" className="responsive compair_table text-center">
                    <thead>
                        <tr>
                            {universities.map((university, index) => (
                                <th key={index} className="max-with">
                                    <div className='text-center'>
                                        <img src={university.logo} className="compair_college_logo" loading="lazy" alt="Nayeedisha" />
                                        <h5 className="mt-2 f-16 text-transform">{university.name}</h5>
                                        <Badge bg="primary" onClick={() => handleBadgeClick(university)}>
                                            Know University in 2 Min <HiMiniInformationCircle />
                                        </Badge>
                                    </div>
                                </th>
                            ))}
                            {universities.length < 3 && (
                                <th className="max-with text-center">
                                    <div className="text-center">


                                        <GoPlusCircle className="plusicon" />
                                        <p >Add College</p>

                                    </div>
                                </th>
                            )}
                        </tr>
                    </thead>
                    <th className='comparebord_bg py-2' colSpan={3}>Countries</th>


                    <tbody>
                        {[
                            { label: '', key: 'country' },
                            // { label: '', key: 'university_contactno' },




                        ].map(({ label, key }) => (
                            <tr key={key}>

                                {universities.map((university, index) => (
                                    <td key={index}>{university[key] || 'N/A'}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                    <th className='comparebord_bg py-2' colSpan={3}>University Contact Details</th>


                    <tbody>
                        {[
                            // { label: '', key: 'country' },
                            { label: '', key: 'university_contactno' },




                        ].map(({ label, key }) => (
                            <tr key={key}>

                                {universities.map((university, index) => (
                                    <td key={index}> <IoCallOutline /> {university[key] || 'N/A'}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>

                    <OverlayTrigger placement="top" overlay={tooltip}>
                        <th className='comparebord_bg py-2' colSpan={3}>Total Budget <HiMiniInformationCircle /></th>
                    </OverlayTrigger>



                    <tbody>
                        {[
                            { label: '', key: 'total_budget' },{ label: '', key: 'currency' },
                            ,


                        ].map(({ label, key }) => (
                            <tr key={key}>

                                {courses.map((courses, index) => (
                                    <td key={index}>{courses[key] || 'N/A'}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>

                   
                        <th className='comparebord_bg py-2' colSpan={3}>Total Duration</th>
                 



                    <tbody>
                        {[
                            { label: '', key: 'duration' },



                        ].map(({ label, key }) => (
                            <tr key={key}>

                                {courses.map((courses, index) => (
                                    <td key={index}>{courses[key] || 'N/A'}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>

                    <th className='comparebord_bg py-2' colSpan={3}>Recognition</th>
                 



                 <tbody>
                     {[
                         { label: '', key: 'recognition' },



                     ].map(({ label, key }) => (
                         <tr key={key}>

                             {universities.map((courses, index) => (
                                 <td key={index}>{courses[key] || 'N/A'}</td>
                             ))}
                         </tr>
                     ))}
                 </tbody>

                 <th className='comparebord_bg py-2' colSpan={3}>Intake </th>
                 



                 <tbody>
                     {[
                         { label: '', key: 'intake_for_mbbs' },



                     ].map(({ label, key }) => (
                         <tr key={key}>

                             {universities.map((courses, index) => (
                                 <td key={index}>{courses[key] || 'N/A'}</td>
                             ))}
                         </tr>
                     ))}
                 </tbody>
                 <th className='comparebord_bg py-2' colSpan={3}>Medium Of Teaching</th>
                 



                 <tbody>
                     {[
                         { label: '', key: 'medium_of_teaching' },



                     ].map(({ label, key }) => (
                         <tr key={key}>

                             {universities.map((courses, index) => (
                                 <td key={index}>{courses[key] || 'N/A'}</td>
                             ))}
                         </tr>
                     ))}
                 </tbody>

                 <th className='comparebord_bg py-2' colSpan={3}>Required Percentage</th>
                 



                 <tbody>
                     {[
                         { label: '', key: 'minimum_percentage_for_mbbs' },



                     ].map(({ label, key }) => (
                         <tr key={key}>

                             {universities.map((courses, index) => (
                                 <td key={index}>{courses[key] || 'N/A'}%</td>
                             ))}
                         </tr>
                     ))}
                 </tbody>

                 <tbody>
                 {[
                         { label: '', key: '' },



                     ].map(({ label, key }) => (
                         <tr key={key}>

                             {universities.map((courses, index) => (
                                 <td key={index}><Button  data-bs-toggle="modal"
                                 data-bs-target="#exampleModal"
                                 data-bs-whatever="@mdo" variant="contained" color="success">Apply Now</Button></td>
                                 
                                 
                             ))}
                         </tr>
                     ))}
                 </tbody>
                </Table>

            </div>
            <br></br>

            {/* Modal to show selected university */}
            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className='compare_titel' id="contained-modal-title-vcenter">
                        Know University in 2 mins
                        <div className='d-flex mt-2'>
                            <span className='call'>
                               <a href='tel:8860031900' className='text-white'> <IoIosCall /> 8860031900</a>
                            </span>
                            <span className='whatsapp'>
                               <a href='https://wa.me/+918860031900' className='text-white'> <FaWhatsapp /> Whatsapp </a>
                            </span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='college_compare'>{selectedUniversity?.name}</p>
                    {/* Additional information about the selected university can be displayed here */}
                    <Accordion className='compare_accordion' defaultActiveKey="0" flush>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header><IoReaderOutline className='about_readmore' /> &nbsp; About University</Accordion.Header>
                            <Accordion.Body className='mx-1'>

                                <div dangerouslySetInnerHTML={{ __html: selectedUniversity?.aboutuniversity || '' }} />
                            </Accordion.Body>
                        </Accordion.Item>

                        <br></br>
                    </Accordion>
                </Modal.Body>

            </Modal>

            {/* model Name Selected */}

            <Modal
                show={applyNow}
                onHide={() => setApplyNow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className='compare_titel' id="contained-modal-title-vcenter">
                       Apply Now
                        <div className='d-flex mt-2'>
                            <span className='call'>
                               <a href='tel:8860031900' className='text-white'> <IoIosCall /> 8860031900</a>
                            </span>
                            <span className='whatsapp'>
                               <a href='https://wa.me/+918860031900' className='text-white'> <FaWhatsapp /> Whatsapp </a>
                            </span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
            

            </Modal>
        </>
    );
};

export default CompareColleges;