import React, { useEffect, useState } from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Accordion from 'react-bootstrap/Accordion';
import searchicon from '../img/search-icon.png';
import { Spinner, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { TfiViewListAlt, TfiViewGrid } from "react-icons/tfi";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Pagination from 'react-bootstrap/Pagination';
import { GrPowerReset } from "react-icons/gr";
import Badge from '@mui/material/Badge';
import { BsFilterCircleFill } from "react-icons/bs";
import russia from "../img/Flag_of_Russia.png";
import nepal from "../img/images_nepal.jpeg";
import India from "../img/Flag_of_India.png";
import { IoIosArrowRoundForward } from "react-icons/io";



import Card from 'react-bootstrap/Card';
const Universitylist = () => {
    const [universities, setUniversities] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [filteredUniversities, setFilteredUniversities] = useState([]);
    const [courseFilter, setCourseFilter] = useState('');
    const [stateFilter, setStateFilter] = useState('');
    const [cityFilter, setCityFilter] = useState('');
    const [studyModeFilter, setStudyModeFilter] = useState('');
    const [countryFilter, setCountryFilter] = useState('');
    const [filterOptions, setFilterOptions] = useState({
        courses: [],
        countries: {},
        states: [],
        cities: [],
    });
    const [viewMode, setViewMode] = useState('grid');
    const [currentPage, setCurrentPage] = useState(1);
    const [universitiesPerPage] = useState(7);

    const [colleges, setColleges] = useState([]);
    const [showDropdown, setShowDropdown] = useState([false, false, false]);


        // Effect to set the first country as the default filter
        useEffect(() => {
            const countries = Object.keys(filterOptions.countries);
            if (countries.length > 0 && !countryFilter) {
                setCountryFilter(countries[0]); // Set the first country as the default
            }
        }, [filterOptions.countries, countryFilter]);

        

    useEffect(() => {
        fetch('https://webcrmapi.nayeedisha.in/api/university-details')
            .then(response => response.json())
            .then(data => setColleges(data))
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    const handleAddCollegeClick = (index) => {
        const newShowDropdown = showDropdown.map((value, idx) => (idx === index ? true : value));
        setShowDropdown(newShowDropdown);
    };

    
    // Debugging useEffect to log countryFilter whenever it updates
    useEffect(() => {
        console.log("Current countryFilter: ", countryFilter);
    }, [countryFilter]);

  

    useEffect(() => {
        fetch('https://webcrmapi.nayeedisha.in/api/university-list')
            .then((response) => response.json())
            .then((data) => {
                setUniversities(data);
                setIsLoading(false);

                const courses = [...new Set(data.map(university => university.course))].filter(Boolean);
                const countries = data.reduce((acc, university) => {
                    acc[university.country] = (acc[university.country] || 0) + 1;
                    return acc;
                }, {});
                const states = [...new Set(data.map(university => university.state))].filter(Boolean);
                const cities = [...new Set(data.map(university => university.city))].filter(Boolean);

                setFilterOptions({ courses, countries, states, cities });
                setFilteredUniversities(data); // Initialize with all data
            })
            .catch((error) => {
                setIsLoading(false);
                console.error('Error fetching data:', error);
            });
    }, []);

    useEffect(() => {
        filterUniversities();
    }, [courseFilter, stateFilter, cityFilter, studyModeFilter, countryFilter]);

    const filterUniversities = () => {
        let filtered = universities;
        if (countryFilter) {
            filtered = filtered.filter(university => university.country === countryFilter);
        }
        if (stateFilter) {
            filtered = filtered.filter(university => university.state === stateFilter);
        }
        if (cityFilter) {
            filtered = filtered.filter(university => university.city === cityFilter);
        }
        if (courseFilter) {
            filtered = filtered.filter(university => university.course === courseFilter);
        }
     
     
     
        

        setFilteredUniversities(filtered);
        setCurrentPage(1); // Reset to the first page after filtering
    };

    useEffect(() => {
        if (countryFilter) {
            const country = [...new Set(universities.filter(university => university.country === countryFilter).map(university => university.country))].filter(Boolean);
            const states = [...new Set(universities.filter(university => university.country === countryFilter).map(university => university.state))].filter(Boolean);
            const cities = [...new Set(universities.filter(university => university.country === countryFilter).map(university => university.city))].filter(Boolean);
            setFilterOptions(prevOptions => ({
                ...prevOptions,
                country,
                states,
                cities,
            }));
            setStateFilter('');
            setCityFilter('');
        } else {
            const country = [...new Set(universities.map(university => university.country))].filter(Boolean);
            const states = [...new Set(universities.map(university => university.state))].filter(Boolean);
            const cities = [...new Set(universities.map(university => university.city))].filter(Boolean);
            setFilterOptions(prevOptions => ({
                ...prevOptions,
                country,
                states,
                cities,
            }));
        }
    }, [countryFilter, universities]);


    const handleClick = (event) => {
        setCurrentPage(Number(event.target.id));
    };

    const indexOfLastUniversity = currentPage * universitiesPerPage;
    const indexOfFirstUniversity = indexOfLastUniversity - universitiesPerPage;
    const currentUniversities = filteredUniversities.slice(indexOfFirstUniversity, indexOfLastUniversity);

    const renderPageNumbers = [];
    for (let i = 1; i <= Math.ceil(filteredUniversities.length / universitiesPerPage); i++) {
        renderPageNumbers.push(i);
    }

    return (
        <>
            <div className='container pt-3'>
                <Breadcrumb>
                    <Breadcrumb.Item href="#" id="crumb">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="" id="crumb">Study Abroad</Breadcrumb.Item>
                </Breadcrumb>
            </div>
           

            <div className='abt-section-news pt-5 pb-5 text-center'>
                <div className='container'>
                    <h3 className="text-white"> Study Abroad Countries</h3>
                    {/* <h3  className="we-provide mt-4 text-white">Study Abroad Countries</h3> */}
                    <div className='bds-news'></div>
                    <div id='brdrs'></div>
                </div>
                </div>

  

            <div className='container mt-5'>
                <div className='row'>
                <div className='col-md-3 mt-3'>
                <a href='mbbs-in-india' className='text-black'>   
                 <Card>
      <Card.Img variant="top" src={India} className="img-fluid" />
      <Card.Body>
        <Card.Title>MBBS In India</Card.Title>
        <Card.Text>
        MBBS in India is one of the most recommended courses all over the globe. When we talk about the India MBBS.
        </Card.Text>
        <button className='btn btn-outline-primary'>Read More</button>
      </Card.Body>
    </Card> 
    </a>
                    </div>
                    <div className='col-md-3 mt-3'>
                    <Card>
      <Card.Img variant="top" src={russia} className="img-fluid" />
      <Card.Body>
        <Card.Title>MBBS In Russia</Card.Title>
        <Card.Text>
        Russia is the biggest country of the world geographically located in both European and Asian territories
        </Card.Text>
       <a href='mbbs-in-russia'><button className='btn btn-outline-primary'>Read More</button></a> 
      </Card.Body>
    </Card>
                    </div>

                    <div className='col-md-3 mt-3 d-none'>
                    <Card >
      <Card.Img variant="top" src={nepal} className="img-fluid" />
      <Card.Body>
        <Card.Title>MBBS In Nepal</Card.Title>
        <Card.Text>
          Some quick example text to build on the card title and make up the
          bulk of the card's content.
        </Card.Text>
        <button className="btn btn-outline-primary" >
                        Read More &nbsp; <IoIosArrowRoundForward />
                     </button>
      </Card.Body>
    </Card>
                    </div>


                    <div className='col-md-3 mt-3 d-none'>
                    <Card >
      <Card.Img variant="top" src={russia} className="img-fluid" />
      <Card.Body>
        <Card.Title>Card Title</Card.Title>
        <Card.Text>
          Some quick example text to build on the card title and make up the
          bulk of the card's content.
        </Card.Text>
        <button className='btn btn-outline-primary'>Read More</button>
      </Card.Body>
    </Card>
                    </div>
                </div>
            </div>
            <br></br>

        
        </>
    );
};

export default Universitylist;
