import { React, useEffect, useState, Component, Fragment } from "react";
import aboutusbanner from '../img/aboutusbanner.png';
import { Bs1CircleFill, Bs2CircleFill, Bs3CircleFill, Bs4CircleFill, Bs5CircleFill, Bs6CircleFill, Bs7CircleFill } from "react-icons/bs";
import Admissionimg from '../../img/admission.svg'
import { BsGlobe } from "react-icons/bs";
import OwlCarousel from "react-owl-carousel";
import "../../../node_modules/owl.carousel/dist/assets/owl.carousel.css";
import "../../../node_modules/owl.carousel/dist/assets/owl.theme.default.css";
import { Spinner, Table } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import lister1 from '../img/lister1.jpg';
import iconlister from '../img/iconlister.jpg';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import apiUrl from '../../setting/apiConfig';
const Mbbsinnepal = () => {
  const options = {
    loop: true,
    margin: 10,
    items: 1,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
        nav: true,

      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };
  const [universities, setUniversities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    fetch('https://webcrmapi.nayeedisha.in/api/university-details')
      .then((response) => response.json())
      .then((data) => {
        // Assuming 'results' contains the array of universities
        setUniversities(data);
        setIsLoading(false);

      })
      .catch((error) => {
        setIsLoading(false);
        console.error('Error fetching data:', error);
      });
  }, []);


  const [russiaUniversities, setRussiaUniversities] = useState([]);
  const [nepalUniversities, setNepalUniversities] = useState([]);


  // show university according country
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        // Check if data is available in localStorage
        const cachedData = localStorage.getItem('university-details');

        if (cachedData) {
          const universityData = JSON.parse(cachedData); // Use cached data

          // Filter universities for Russia and Nepal
          const russiaFiltered = universityData.filter(university => university.country === 'Russia');
          const nepalFiltered = universityData.filter(university => university.country === 'Nepal');

          // Update state with filtered data
          setRussiaUniversities(russiaFiltered);
          setNepalUniversities(nepalFiltered);

          setIsLoading(false);
        } else {
          const universityResponse = await fetch(apiUrl + 'university-details');
          const universityData = await universityResponse.json();

          // Store fetched data in localStorage
          localStorage.setItem('university-details', JSON.stringify(universityData));

          // Filter universities for Russia and Nepal
          const russiaFiltered = universityData.filter(university => university.country === 'Russia');
          const nepalFiltered = universityData.filter(university => university.country === 'Nepal');

          // Update state with filtered data
          setRussiaUniversities(russiaFiltered);
          setNepalUniversities(nepalFiltered);

          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  return (
    <>



      {/* section1 */}

      <div className='abt-section pt-5 pb-5'>


        <div className='container'>

          <h3>MBBS in Nepal</h3>
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>
              MBBS In Nepal
            </Breadcrumb.Item>

          </Breadcrumb>
          <div className='bds'></div>
          <div id='brdrs'></div>

          {/* <p>It is a long established fact that a reader.</p> */}


        </div>


      </div>




      <div className='container abt-2 mt-5 mb-5'>
        <h3 className="fw-bold my-3">Study MBBS in<span className="text-primary">&nbsp; Nepal</span></h3>


        <p className='mb-4'>In Nepal, MBBS has been recognized as an excellent choice among many international medical students. Nepal Medical Universities have become growing more attractive to students. Nepal has taken multiple excellent actions to improve its healthcare infrastructure and medical education system in the gloom of the Himalayan Mountains.
        </p>
        <p>Nepal has the same teaching pattern as India and similarly adheres to the NMC (MCI) Syllabus and study pattern established by the Medical Council of India. Many additional similarities exist between the two countries, such as the fact that the MBBS program lasts the same amount of time in both countries—in Nepal, it takes five plus one year to finish the medical program. One year is devoted to internships, while the first four and a half years are devoted to the theoretical study of clinical and basic science.
        </p>

        <p>In comparison with India and other developed nations, Nepal also has a medical institution termed the Medical Council of India, which supervises the operations of Nepal's universities and medical schools on a regular basis. The Medical Council of India also approves Nepal's medical institutions.
        </p>


        <hr></hr>
        <h2 className="fw-bold my-3 h3">Why Choose Nepal for<span className="text-primary">&nbsp; MBBS?</span></h2>

        <p>Nepal is a suitable location for Indian candidates and others to continue medical education with low expenses for tuition and numerous possibilities to become a licensed doctor. Universities in Nepal offer outstanding amenities, innovative technologies, and contemporary teaching tools so that students can learn and study in comfort.
        </p>
        <p>Nepal's government has taken more actions to advance the medical field for learning and teaching since the middle of the 20th century. But a few years later, it completed the Institute of Medicine, which is part of Tribhuvan University and provides medical speciality training. Nepal has established numerous medical educational and healthcare training facilities and colleges throughout the past 20 years. The primary driver behind the growth of medical facilities globally is the enormous demand for healthcare professionals in the modern world. MBBS courses are offered at affordable prices in many different nations.
        </p>
        <p>When compared to other economically advanced nations like India, Nepal also has a healthcare organization called the Medical Council of India, which regularly oversees the activities of the country's universities and medical institutions. Nepal's medical facilities are also approved by the Indian Medical Council</p>


      </div>
      <section id="mbbs_abroad" className="py-4 bg-light py-4" >
        <div className="container bg-light py-3">

          <h3 className="fw-bold my-3">
            MBBS in Nepal
            <span className="text-primary">&nbsp; VS India</span>
          </h3>

          <b>  Nepal medical university fees are less reasonable as compared with Indian Private medical colleges.</b>

          <Table striped bordered hover className="mt-3">
            <thead>
              <tr>
                <th>Nepal

                </th>
                <th>India</th>


              </tr>
            </thead>
            <tbody>
              <tr>

                <td>Education costs are affordable in comparison with Indian Private medical colleges.</td>
                <td>In India, Private medical college fees are very high and more expensive.</td>
              </tr>
              <tr>

                <td>Competition is very low compared with Indian universities and seats are not limited</td>
                <td>Competition is very high as per analysis with other countries and seats are limited.</td>
              </tr>
              <tr>
                <td>Living expenses are very low and affordable in comparison with India and other countries </td>
                <td>Living expenses are high as per the standard.</td>


              </tr>


            </tbody>
          </Table>
        </div>



      </section>

      <div className='container abt-2 mt-5 mb-5'>
        <h3 className="fw-bold my-3">About Eligibility Criteria for <span className="text-primary">&nbsp; MBBS in Nepal</span></h3>


        <p className='mb-4'>Applicants must meet the requirements of Nepali universities to take admission to MBBS programs. Applicants can apply for admission if they are selected to be eligible and possess all necessary document requirements. All of Nepal's medical colleges have the same requirements for admission and the following lists the requirements for MBBS in Nepal:</p>
        <p>Nepal has the same teaching pattern as India and similarly adheres to the NMC (MCI) Syllabus and study pattern established by the Medical Council of India. Many additional similarities exist between the two countries, such as the fact that the MBBS program lasts the same amount of time in both countries—in Nepal, it takes five plus one year to finish the medical program. One year is devoted to internships, while the first four and a half years are devoted to the theoretical study of clinical and basic science.
        </p>
        <hr></hr>
        <p><b>Academic Requirements:</b>Candidates must have concluded their 10+2 schooling or any additional comparable re-evaluation from an acknowledged council of the country and have acquired at least 50% marks in primary subjects like Physics, Chemistry, Biology, and English.
        </p>

        <p><b>NEET Qualification:</b>Candidates must have competent NEET entrance assessment and acquired qualifying Scores to get admission to medical colleges.
        </p>
        <p><b>Age Limit:</b>The candidate must have been not younger than 17 years old through December 31st of the same year when going to apply.
        </p>
        <hr></hr>
        <h2 className="fw-bold h3 my-3">Admission Process </h2>

        <p>If you are planning to pursue an MBBS degree in Nepal medical colleges, then you have to go along with the following procedure set by MCN.
        </p>
        <ul>
          Application Steps for Taking Admission in Nepal
          <li>Apply online in the university portal</li>
          <li>Send the scanned required documents like 10th, and 12th passing certificates.</li>
          <li>Wait for the Invitation letter that you will get from the University within 48 hours</li>
          <li>Pay the Registration fee after getting the invitation letter</li>
          <li>Inform your date of arrivals</li>
          <li>Fly and go to attend your classes
          </li>
        </ul>
        <ul className="mt-2">
          <b>Application Steps for Taking Admission in Nepal</b>
          <ul>
            <li>Apply online in the university portal</li>
            <li>Send the scanned required documents like 10th, and 12th passing certificates.</li>
            <li>Wait for the Invitation letter that you will get from the University within 48 hours</li>
            <li>Pay the Registration fee after getting the invitation letter</li>
            <li>Inform your date of arrivals</li>
            <li>Fly and go to attend your classes</li>
          </ul>
        </ul>

        <ul className="mt-2">
          <b>Required Documents</b>
          <ul>
            <li>Xerox of passing certificates</li>
            <li>Copy of Invitation letter.</li>
            <li>Medical and Birth certificates.</li>
            <li>Some Passport size photographs</li>
          
          </ul>
        </ul>
<hr></hr>

        <p><b>Direct Admission Options: </b>Nayee Disha offers direct admission options. Applicants should contact and we ensure better guidance with experienced counsellors.</p>
        <p><b>University Selection: </b>Nayeedisha Education offers excellent guidance in choosing a university for students as per your academic record, curriculum, location and other student services.</p>
        <p><b>Application Help: </b>The team of Nayeedisha Education provides support regarding your application assistance for the application process. Consultants will assist applicants in preparing their applications, ensuring all required documents are accurate and managed to be submitted on time.</p>

      </div>

      <hr></hr>

      <br></br>
      <div className="container">
        <div className="text-center">
          <h3 className='border_before'>Nepal Colleges</h3>
        </div>

        <div className="row">
          {nepalUniversities.map((university) => (
            <div key={university.uid} className="col-md-4 mt-4">
              <div className="card" id="icons-crd" style={{ width: '100%' }}>
                <img
                  src={university.cover_image}
                  className="card-img-top"
                  alt="NayeeDisha"
                />
                <div className="card-body iconerss-card">
                  <img
                    src={university.logo}
                    alt="NayeeDisha"
                  />
                  <Link to={`/university/${university.slug}`}>
                    <h5 className="mt-1 text-transform">{university.name}</h5>
                  </Link>
                  <div>
                    <div className="pt-2 college_part">
                      <p className="para1" style={{ color: '#000' }}>
                        Establish Year -
                        <span className="ugc" style={{ color: '#000' }}>
                          {university.estb || '1899'}
                        </span>
                      </p>
                      |
                      <p className="para1">
                        Country -
                        <span className="ugc" style={{ color: '#000' }}>
                          {university.country || 'Russia'}
                        </span>
                      </p>
                    </div>
                    <div className="d-flex justify-content-between mt-3">

                      <Link to={`/university/${university.slug}`}>
                        <Button className="btn" variant="outline-success">Read More</Button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <br></br>
    </>
  );
};

export default Mbbsinnepal;

